<template>
    <div class="col-12 d-block d-md-none p-0 fixed-position" id="mobile-nav" style="z-index:15">
        <div class="mobile-nav-btn">
            <button class="rounded-3">
                <i class="bi bi-list"></i>
            </button>
        </div>
        <div class="mobile-nav-bg bg-deactive"></div>
        <div class="mobile-nav-side mobile-nav-deactive">
            <v-card id="mobile-nav-target" width="256" height="100%" elevation="6" class="mx-0">
                <v-navigation-drawer permanent class="mobile-nav-side-bg">       
                    <!-- =========== Account Info === -->
                    <div class="nav-side-margin">
                        <div class="side-profile-pic mx-auto d-flex align-items-end justify-content-center">
                            <img src="../assets/images/profile/default.png" class="" alt="img" draggable="false">
                        </div>
                        <div class="text-center mt-2">
                            <h6 class="m-0"><b>                                    
                                {{ Username }}                                    
                            </b></h6>
                            <small class="m-0">
                                Lincoln Law Firm
                            </small>
                        </div>
                    </div>                               
                    <v-divider class="m-0"></v-divider>
                    <!-- =========== Account Info === -->
                   
                        <!-- ========== Instrument Generator === -->
                        <SideNavigationItems />
                        <!-- ========== Instrument Generator END === -->
                      
                </v-navigation-drawer>
            </v-card>
        </div>
    </div>
</template>

<script>
import {mobileSideNav} from '../assets/js/custom';
import SideNavigationItems from '@/views/SideNavigationItems'
export default {
    name: "SideNavigation",
    components: {
        SideNavigationItems
    },
    data () {
      return {
       
        right: null,
      }
    },
    watch: {

    },
    methods: {
       
        

    },
    created() {
        
    },
    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    },
    mounted(){
        mobileSideNav.setupMobileNav()
    }

};
</script>

<style>
    @import '../assets/css/nav.css';
</style>
