<template>
<div class="container">
    <div class="row body-container">
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <!-- ========== code start here  ========== -->

                <!-- ========== Title  ========== -->
                <div style="text-align:center">
                    <h2>Create New Instrument</h2>
                </div>
                <!-- ========== Table  ========== -->
                <div class="border px-2 rounded">
                    <div class="col-12">
                        <label for="name">Instrument Type:</label>
                    <select style="cursor: pointer;" v-model="instrument_type" >
                        <option disabled value="">Please select one</option>
                        <option v-for="(result, index) in results"
                                :key="index" :value="result.ID">
                            {{ result.name }}</option>
                    </select>
                    <v-divider></v-divider>
                    </div>
                </div>  
                <div class="center-align col-12">
                    <p v-if="feedback" class="red-text">{{ feedback }}</p>  
                         
                        <v-btn color="blue" dark @click="create_instrument">
                            <span>Next</span>
                        </v-btn>
                  
                </div>    
                <!-- ========== code end here  ========== -->
            </div>            
        </div>          
    </div>
</div>
</template>

<script>
import axios from 'axios';
import store from "../../store";

export default {
    name: "UserList",
    components: {

    },
    data() {

        return {
 
            instrument_type: "1",
            url: "",
            results:[],
            instrument_id:"",
            feedback:"",
            reference_code: "",
            reference_boolean: "",
        };
    },
    methods: {
        show_instrument(){
            axios.post(this.url, 
                {
                    request: 1,
                }
                )
                .then(response => {
                    this.results = response.data;
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        generate_reference_no() {
            var length = 5
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
                charactersLength));
            }
            this.reference_code = result
            
        },
        check_reference(){
            this.url = this.HostUrl + this.ScriptUrl + "instrument.php"
            axios.post(this.url, 
                {
                    request: 5,
                    reference_code: this.reference_code
                }
                )
                .then(response => {
                    this.reference_boolean = response.data
                    console.log(this.reference_boolean)
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        create_instrument(){
            
            this.url = this.HostUrl + this.ScriptUrl + "instrument.php"
           

            switch(this.instrument_type){
                //will instrument
                case '1':
                    //create random reference
                   
                    //store in database and get id
                    while(this.reference_boolean == '1') {
                        this.generate_reference_no()
                        this.check_reference()
                    }
                    axios.post(this.url, 
                        {
                            request: 2,
                            instrument_type: this.instrument_type,
                            reference_code: this.reference_code,
                            user_id: this.UserID
                        }
                        )
                        .then(response => {
                            console.log(response.data)
                            if(response.data != "Error") {
                                this.instrument_id = response.data;
                                // store in vuex
                                store.commit("InstrumentID", this.instrument_id);
                                store.commit("InstrumentReference", this.reference_code);
                                //head to next page
                                this.$router.push("/testator");
                            }
                        })
                        
                        .catch(function (error) {
                            console.log(JSON.stringify(error));
                            console.log("error response :" + error.response)
                            console.log("error status :" + error.status)
                        });
                    
                   
                    break;
            }
        }
       
    },
    created() {
        this.url = this.HostUrl + this.ScriptUrl + "instrument_type.php"
        this.show_instrument()
     
        this.generate_reference_no()
        this.check_reference()
     
    },


    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        }

    },

    mounted() {
        
    }

};
</script>

<style>
@import '../../assets/css/body.css';
select {
    display: grid;
}



</style>
