<template>
<div class="container">
    <div class="row body-container">
        <div class="col-12">
             <!-- ========== Title  ========== -->
            <div style="text-align:center">
                <h2>Instrument Information</h2>
                Document Reference No. {{ InstrumentReference }}
            </div>
        </div>
        <!-- +++++++++ BODY CARD TEMPLATES +++++++++ -->
        <!-- <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                ---- CONTENT -----
            </div>
        </div> -->
        <!-- +++++++++ BODY CARD TEMPLATES END +++++++++ -->

        <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                 <!-- ========== Testator Information  ========== -->
                <div>
                    <h3>Testator Information</h3>
                    <hr>
                    <div v-for="(testator_result, index) in testator_results" :key="index">
                        <p><b>Name:</b> {{ testator_result.name }}</p>
                        <p><b>Phone:</b> {{ testator_result.phone }}</p>
                        <p><b>IC:</b> {{ testator_result.ic }}</p>
                        <p><b>Address:</b> {{ testator_result.unit_no + "," +  testator_result.address + "," + testator_result.postcode + " " + testator_result.city + "," + testator_result.states + "," + testator_result.country }}</p>
                        <p><b>Remark:</b> {{ testator_result.remark }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <!-- ========== Executor Information  ========== -->
                <div>
                    <h3>Executor Information</h3>
                    <hr>
                    <div v-for="(executor_result, index) in executor_results" :key="index">
                        <p><b>Name:</b> {{ executor_result.name }}</p>
                        <p><b>Phone:</b> {{ executor_result.phone }}</p>
                        <p><b>IC:</b> {{ executor_result.ic }}</p>
                        <p><b>Address:</b> {{ executor_result.unit_no + "," +  executor_result.address + "," + executor_result.postcode + " " + executor_result.city + "," + executor_result.states + "," + executor_result.country }}</p>
                        <p><b>Relationship:</b> {{executor_result.relationship_name }}</p>
                        <p><b>Remark:</b> {{ executor_result.remark }}</p>
                        <br>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <!-- ========== Beneficiary Information  ========== -->
                <div>
                    <h3>Beneficiary Information</h3>
                    <hr>
                    <div v-for="(beneficiary_result, index) in beneficiary_results" :key="index">
                        <p><b>Name:</b> {{ beneficiary_result.name }}</p>
                        <p><b>Phone:</b> {{ beneficiary_result.phone }}</p>
                        <p><b>IC:</b> {{ beneficiary_result.ic }}</p>
                        <p><b>Address:</b>  {{ beneficiary_result.unit_no + "," +  beneficiary_result.address + "," + beneficiary_result.postcode + " " + beneficiary_result.city + "," + beneficiary_result.states + "," + beneficiary_result.country }}</p>
                        <p><b>Relationship:</b> {{ beneficiary_result.relationship_name }}</p>
                        <p><b>Remark:</b> {{ beneficiary_result.remark }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <!-- ========== Estate Information  ========== -->
                <div>
                    <h3>Estate Information</h3>
                    <hr>
                    <div v-for="(estate_result, index) in estate_results" :key="index" >
                        <p><b>Estate Name:</b> {{ estate_result.name }}</p>
                        <p><b>Estate Type:</b> {{ estate_result.estate_type_name }}</p>
                        <p><b>Remark:</b> {{ estate_result.remark }}</p>
                    <br>
                    <!-- === beneficiary === -->
                    <div v-if="estate_result.estate_beneficiary_results!= 'empty'">
                        <div v-for="(beneficiary_data, index) in estate_result.estate_beneficiary_results" :key="index" >
                            <hr>
                            <p><b>Beneficiary :</b> {{ beneficiary_data.name }}</p>
                            <p><b>Proportion :</b> {{ beneficiary_data.proportion }}</p>
                            <p><b>Beneficiary Remark :</b> <span>{{ beneficiary_data.remark }}</span></p> 
                        </div> 
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-if="guardian_info">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <!-- ========== Guardian Information  ========== -->
                <div>
                    <h3>Guardian Information</h3>
                    <hr>
                    <div v-for="(guardian_result, index) in guardian_results" :key="index">
                        <p><b>Name:</b> {{ guardian_result.name }}</p>
                        <p><b>Phone: </b>{{ guardian_result.phone }}</p>            
                        <p><b>IC: </b>{{ guardian_result.ic }}</p>
                        <p><b>Address: </b>{{ guardian_result.unit_no + "," +  guardian_result.address + "," + guardian_result.postcode + " " + guardian_result.city + "," + guardian_result.states + "," + guardian_result.country }}</p>
                        <p><b>Relationship: </b>{{ guardian_result.relationship_name }}</p>                        
                        <v-checkbox v-model="guardian_result.subtitude" label = "Subtitude"></v-checkbox>
                        <v-checkbox v-model="guardian_result.joint" label = "Joint"></v-checkbox>           
                        <p><b>Remark: </b>{{ guardian_result.remark }}</p>
                        <br>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-if="trust_info">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <!-- ========== Trust Information  ========== -->
                <div>
                    <h3>Trust Information</h3>
                    <hr>
                    <div v-for="(trust_result, index) in trust_results" :key="index">
                        <p><b>Name: </b>{{ trust_result.name }}</p>
                        <b>Beneficiaries:</b>
                        <p class="my-0 py-0" v-for="(beneficiary_data, index) in trust_result.trust_beneficiary_results"
                        :key="index" >{{ beneficiary_data.name }}</p>
                        <hr>
                        <p><b>Where does the trust fund come from: </b><br>{{ trust_result.source_fund }}</p>
                        <p><b>How should the trust fund be spent: </b><br>{{ trust_result.purpose }}</p>
                        <p><b>At which point the trust is expired: </b><br>{{ trust_result.expire }}</p>
                        <p><b>How to dispose the residuary trust fund, if any: </b><br>{{ trust_result.dispose }}</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-if="declaration_marry_soon_info">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <!-- ========== >Declaration Information (In view of getting marry soon)  ========== -->
                <div>
                    <h3>Declaration Information (Marry Soon)</h3>
                    <hr>
                    <div v-for="(declaration_marry_soon_results, index) in declaration_marry_soon_results" :key="index">
                        <p><b>Name: </b>{{ declaration_marry_soon_results.name }}</p>
                        <p><b>IC: </b>{{ declaration_marry_soon_results.ic }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-if="declaration_inheritance_refusal_info">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <!-- ========== >Declaration Information (Inheritance Refusal)  ========== -->
                <div>
                    <h3>Declaration Information (Inheritance Refusal)</h3>
                    <hr>
                    <div v-for="(declaration_inheritance_refusal_result, index) in declaration_inheritance_refusal_results" :key="index">
                        <p><b>Name: </b>{{ declaration_inheritance_refusal_result.name }}</p>
                        <p><b>IC: </b>{{ declaration_inheritance_refusal_result.ic }}</p>
                        <p><b>Relationship: </b>{{ declaration_inheritance_refusal_result.relationship_name }}</p>
                    </div>
                </div>
            </div>
        </div>       
    </div> 
</div>    
</template>

<script>
import axios from 'axios';

export default {
    name: "UserList",
    components: {

    },
    data() {

        return {
            guardian_info: false,
            trust_info: false,
            declaration_marry_soon_info: false,
            declaration_inheritance_refusal_info: false,
            testator_results:[],
            executor_results:[],
            beneficiary_results:[],
            estate_results:[],
            guardian_results:[],
            trust_results:[],
            declaration_marry_soon_results:[],
            declaration_inheritance_refusal_results:[],
            url: ""

        };
    },
    methods: {
        show_testator_info() {
            this.url = this.url = this.HostUrl + this.ScriptUrl + "testator.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID,
                })
                .then(response => {
                    this.testator_results = response.data
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_executor_info() {
            this.url = this.url = this.HostUrl + this.ScriptUrl + "executor.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID,
                })
                .then(response => {
                    this.executor_results = response.data
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_beneficiary_info(){
            this.url = this.url = this.HostUrl + this.ScriptUrl + "beneficiary.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID,
                })
                .then(response => {
                    this.beneficiary_results = response.data
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_estate_info(){
            this.url = this.url = this.HostUrl + this.ScriptUrl + "estate_beneficiary.php"
             axios.post(this.url, 
                {
                    request: 5,
                    instrument_id: this.InstrumentID
                }
                )
                .then(response => {
                    this.estate_results = response.data
                    console.log(response.data)
                   
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_guardian_info(){
            this.url = this.url = this.HostUrl + this.ScriptUrl + "guardian.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID,
                })
                .then(response => {
                    this.guardian_results = response.data
                    if(this.guardian_results.length > 0) {

                        this.guardian_info = true
                        this.guardian_results.forEach(function (data) {
                            if(data.joint == 1) {
                                data.joint = true
                            }
                            else {
                                data.joint = false
                            }
                            if(data.subtitude == 1) {
                                data.subtitude = true
                            }
                            else {
                                data.subtitude = false
                            }
                        })
                    }
                   
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        
        show_trust_info(){

            this.url = this.HostUrl + this.ScriptUrl + "trust.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID,
                    
                }
                )
                .then(response => {
            
                    if(response.data.length > 0) {
                        this.trust_results = response.data
                        this.trust_info = true
                    }
                   // console.log(response.data)
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                }); 
        },
        show_declaration_marry_soon_info(){
            this.url = this.HostUrl + this.ScriptUrl + "declaration.php"
            axios.post(this.url, 
                {
                    request: 4,
                    instrument_id: this.InstrumentID,
                }
                )
                .then(response => {
                    
                    if(response.data.length > 0){
                        this.declaration_marry_soon_results = response.data
                        this.declaration_marry_soon_info = true
                    }
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_declaration_inheritance_refusal_info(){
            this.url = this.url = this.HostUrl + this.ScriptUrl + "declaration.php"
            axios.post(this.url, 
                {
                    request: 3,
                    instrument_id: this.InstrumentID,
                })
                .then(response => {
                     if(response.data.length > 0){
                         console.log(response.data)
                        this.declaration_inheritance_refusal_results = response.data
                        this.declaration_inheritance_refusal_info = true
                    }
                    
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        }


        
    },
    created() {
        this.show_testator_info()
        this.show_executor_info()
        this.show_beneficiary_info()
        this.show_estate_info()
        this.show_guardian_info()
        
        this.show_trust_info()
        this.show_declaration_marry_soon_info()
        this.show_declaration_inheritance_refusal_info()
        
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        }
    }

};
</script>

<style>
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
