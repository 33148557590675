<template>
    <div class="d-none d-md-block">
        <!-- vvv sidenav spacing vvv -->
        <div class="nav-side d-none d-md-block p-0" style="z-index:0; opacity:0"></div>
        <!-- ====== sidenav  -->
        <div class="nav-side d-none d-md-block p-0 fixed-side-nav">
            <!-- <div class="nav-side"> -->
                <v-card height="100%" elevation="6" class="mx-0">
                    <v-navigation-drawer permanent class="nav-side-bg" style="width: 100%">       
                        <!-- =========== Account Info === -->
                        <div class="nav-side-margin">
                            <div class="side-profile-pic mx-auto d-flex align-items-end justify-content-center">
                                <img src="../assets/images/profile/default.png" class="" alt="img" draggable="false">
                            </div>
                            <div class="text-center mt-2">
                                <h6 class="m-0"><b>                                    
                                    {{ Username }}                                    
                                </b></h6>
                                <small class="m-0">
                                    Lincoln Law Firm
                                </small>
                            </div>
                        </div>                               
                        <v-divider class="m-0"></v-divider>
                        <!-- =========== Account Info END === -->
                        
                            <!-- ========== Instrument Generator === -->
                            <SideNavigationItems />
                            <!-- ========== Instrument Generator END === -->
                    
                    </v-navigation-drawer>
                </v-card>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>

import SideNavigationItems from '@/views/SideNavigationItems'
export default {
    name: "SideNavigation",
    components: {
        SideNavigationItems
    },
    data () {
      return {
       
        right: null,
      }
    },
    watch: {

    },
    methods: {
       
        

    },
    created() {
        
    },
    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    },

};
</script>

<style>
    @import '../assets/css/nav.css';
</style>
