
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store' 

import HomeView from '../views/HomeView.vue'
import Login from '@/views/Login.vue'
import ForgetPassword from '@/views/ForgetPassword.vue'
//admin control
import Permission from '@/views/admin_control/Permission'
import UserGroup from '@/views/admin_control/UserGroup'
import UserGroup_Permission from '@/views/admin_control/UserGroup_Permission'
import UserList from '@/views/admin_control/UserList'

//instrument will
import Beneficiary from '@/views/will_instrument/Beneficiary'
import Estate from '@/views/will_instrument/Estate'
import Executor from '@/views/will_instrument/Executor'
import CreateInstrument from '@/views/will_instrument/CreateInstrument'
import Testator from '@/views/will_instrument/Testator'
import EstateType from '@/views/will_instrument/EstateType'
import Relationship from '@/views/will_instrument/Relationship'
import InstrumentDraft from '@/views/will_instrument/InstrumentDraft'
//import InstrumentDownload from '@/views/will_instrument/InstrumentDownload'
import InstrumentEditor from '@/views/will_instrument/InstrumentEditor'
import InstrumentInformation from '@/views/will_instrument/InstrumentInformation'
import InstrumentType from '@/views/will_instrument/InstrumentType'
import SearchTestator from '@/views/will_instrument/SearchTestator'
import Guardian from '@/views/will_instrument/Guardian'
import DeclarationTrust from '@/views/will_instrument/DeclarationTrust'
import UpdateTestator from '@/views/will_instrument/UpdateTestator'
//profile
import Profile from '@/views/will_instrument/Profile'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  // login route
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
  // user profile route
  {
    path: '/my-profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  // instruments route
  {
    path: '/beneficiaries',
    name: 'Beneficiary',
    component: Beneficiary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/estate-type',
    name: 'EstateType',
    component: EstateType,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/relationship',
    name: 'Relationship',
    component: Relationship,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/instrument-drafts',
    name: 'InstrumentDraft',
    component: InstrumentDraft,
    meta: {
      requiresAuth: true
    }
  },
  /*
  {
    path: '/instrument-download',
    name: 'InstrumentDownload',
    component: InstrumentDownload,
    meta: {
      requiresAuth: true
    }
  },
  */
  {
    path: '/instrument-editor',
    name: 'InstrumentEditor',
    component: InstrumentEditor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/instrument-information',
    name: 'InstrumentInformation',
    component: InstrumentInformation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/instrument-type',
    name: 'InstrumentType',
    component: InstrumentType,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/estates',
    name: 'Estate',
    component: Estate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/executors',
    name: 'Executor',
    component: Executor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/guardians',
    name: 'Guardian',
    component: Guardian,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/declarations-trusts',
    name: 'DeclarationTrust',
    component: DeclarationTrust,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create-instrument',
    name: 'CreateInstrument',
    component: CreateInstrument,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/search-testator',
    name: 'SearchTestator',
    component: SearchTestator,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/testator',
    name: 'Testator',
    component: Testator,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/update-testator',
    name: 'UpdateTestator',
    component: UpdateTestator,
    meta: {
      requiresAuth: true
    }
  },
  // admin control route
  {
    path: '/permission',
    name: 'Permission',
    component: Permission,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-list',
    name: 'UserList',
    component: UserList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-group',
    name: 'UserGroup',
    component: UserGroup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-group-permission',
    name: 'UserGroup_Permission',
    component: UserGroup_Permission,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.is_login) {
      next({ name: 'Login' })
    } else {
      if(to.name == 'Login') {
        next({ name: 'Home' })
      }
      else {
        next() // go to wherever I'm going
      }
     
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
