<template>    
<div class="container">
    <div class="row body-container">
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <!-- ========== code start here  ========== -->

                <!-- ========== Title  ========== -->
                <div style="text-align:center" class="mb-4">
                    <h2>User Groups & Permissions</h2>
                </div>
                <div class="border rounded-2 p-2">
                    <label for="name">User Group:</label>
                    <select style="cursor: pointer;" v-model="user_group_option" v-on:change="onchangeUserGroup()">
                        <option disabled value="">Please select one</option>
                        <option v-for="(user_group_result, index) in user_group_results"
                                :key="index" :value="user_group_result.ID">
                            {{ user_group_result.name }}</option>
                    </select>
                    <v-divider></v-divider>
                </div>
                <!-- ========== code end here  ========== -->
            </div>                
        </div>         
        <!-- ========== Permission Content Card ================ -->
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <div class="border px-2 rounded overflow-auto">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Permission </th>
                                <th scope="col">View <v-checkbox v-model="view_all" v-on:change="onchangePermission('view_all')"></v-checkbox></th>
                                <th scope="col">Create <v-checkbox v-model="create_all" v-on:change="onchangePermission('create_all')"></v-checkbox></th>
                                <th scope="col">Update <v-checkbox v-model="update_all" v-on:change="onchangePermission('update_all')"></v-checkbox></th>
                                <th scope="col">Delete <v-checkbox v-model="delete_all" v-on:change="onchangePermission('delete_all')"></v-checkbox></th>
                                <th scope="col">Export<v-checkbox v-model="export_all" v-on:change="onchangePermission('export_all')"></v-checkbox></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr v-for="(permission_result, index) in permission_results" :key="index">
                                <td>
                                    {{ permission_result.name }}
                                    <v-checkbox v-model="permission_result.select_all" v-on:change="onchangeSelect(index)"></v-checkbox>
                                </td>
                                <td><v-checkbox v-model="permission_result.view_items" v-on:change="onchangeItem(index)"></v-checkbox></td>
                                <td><v-checkbox v-model="permission_result.create_items" v-on:change="onchangeItem(index)"></v-checkbox></td>
                                <td><v-checkbox v-model="permission_result.update_items" v-on:change="onchangeItem(index)"></v-checkbox></td>
                                <td><v-checkbox v-model="permission_result.delete_items" v-on:change="onchangeItem(index)"></v-checkbox></td>
                                <td><v-checkbox v-model="permission_result.export_items" v-on:change="onchangeItem(index)"></v-checkbox></td>
                            </tr>                     
                        </tbody>
                    </table>
                </div>
            </div>
        </div>         
    <!-- ========== Permission Content Card END ================ --> 
    </div>     
</div>
</template>

<script>
import axios from 'axios';

export default {
    name: "UserList",
    components: {

    },
    data() {

        return {
            
            feedback: null,
            permission_results: [],  
            user_group_results:[],   
            user_group_option: "",
            create_all: false,
            update_all: false,
            delete_all: false,
            export_all: false,
            view_all: false,
        };
    },
    methods: {
        onchangeUserGroup(){
            this.show_user_group_permission()
        },
        onchangeSelect(index){

            if(this.permission_results[index].select_all  == true) {
                this.permission_results[index].view_items  = true
                this.permission_results[index].update_items = true
                this.permission_results[index].delete_items = true
                this.permission_results[index].export_items = true
                this.permission_results[index].create_items = true
            } 
            else {
            
                this.permission_results[index].view_items  = false
                this.permission_results[index].update_items = false
                this.permission_results[index].delete_items = false
                this.permission_results[index].export_items = false
                this.permission_results[index].create_items = false
               
            }
            
            
        },
        onchangePermission(action){
            switch(action){
                case 'create_all':
                    if(this.create_all == true){
                        this.permission_results.forEach(function (data) {
                            data.create_items = true
                        });
                    }
                    else {
                        this.permission_results.forEach(function (data) {
                            data.create_items = false
                        });
                    }
                    break
                case 'update_all':
                    if(this.update_all == true){
                        this.permission_results.forEach(function (data) {
                            data.update_items = true
                        });
                    }
                    else{
                        this.permission_results.forEach(function (data) {
                            data.update_items = false
                        });
                    }
                    break
                case 'delete_all':
                    if(this.delete_all == true){
                        this.permission_results.forEach(function (data) {
                            data.delete_items = true
                        });
                    }
                    else{
                        this.permission_results.forEach(function (data) {
                            data.delete_items = false
                        });
                    }
                    break
                case 'view_all':
                    if(this.view_all == true){
                        this.permission_results.forEach(function (data) {
                            data.view_items = true
                        });
                    }
                    else{
                         this.permission_results.forEach(function (data) {
                            data.view_items = false
                        });
                    }
                    break
                case 'export_all':
                    if(this.export_all == true){
                        this.permission_results.forEach(function (data) {
                            data.export_items = true
                        });
                    }
                    else {
                        this.permission_results.forEach(function (data) {
                            data.export_items = false
                        });
                    }
                    break
            }
                
        },
        onchangeItem(index) {

            if(this.permission_results[index].select_all  == true) {
                if(
                    this.permission_results[index].view_items  == false ||
                    this.permission_results[index].update_items == false ||
                    this.permission_results[index].delete_items == false ||
                    this.permission_results[index].export_items == false ||
                    this.permission_results[index].create_items == false 
                ) {
                    this.permission_results[index].select_all = false
                }
                
            } 
            else {
                if(
                    this.permission_results[index].view_items  == true &&
                    this.permission_results[index].update_items == true &&
                    this.permission_results[index].delete_items == true &&
                    this.permission_results[index].export_items == true &&
                    this.permission_results[index].create_items == true 
                ) {
                    this.permission_results[index].select_all = true
                }
            }
           
            
            
        },
        show_permission() {
            this.url = this.HostUrl + this.ScriptUrl + "permission.php"
            axios.post(this.url, 
                {
                    request: 1,
                    
                })
                .then(response => {
                    this.permission_results = response.data;
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        show_user_group(){
            this.url = this.HostUrl + this.ScriptUrl + "user_group.php"
            axios.post(this.url, 
                {
                    request: 1,
                    
                })
                .then(response => {
                    this.user_group_results = response.data;
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_user_group_permission(){
           
            this.url = this.HostUrl + this.ScriptUrl + "user_group_permission.php"
            axios.post(this.url, 
                {
                    request: 1,
                    user_group_id: this.user_group_option
                })
                .then(response => {
                    this.permission_results = response.data;
                    this.permission_results.forEach(function (data) {
                        if(data.create_items == 1) {
                            data.create_items = true
                        }
                        else {
                            data.create_items = false
                        }
                        if(data.view_items == "1") {
                            data.view_items = true
                        }
                        else {
                            data.view_items = false
                        }
                        if(data.update_items == "1") {
                            data.update_items = true
                        }
                        else {
                            data.update_items = false
                        }
                        if(data.delete_items == "1") {
                            data.delete_items = true
                        }
                        else {
                            data.delete_items = false
                        }
                        if(data.export_items == "1") {
                            data.export_items = true
                        }
                        else {
                            data.export_items = false
                        }
                   
                    })
                    console.log(response.data)
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        update_user_group_permission(){
            //console.log(this.permission_results)
            this.url = this.HostUrl + this.ScriptUrl + "user_group_permission.php"
            this.permission_results.forEach(function (data) {
                if(data.create_items == true) {
                    data.create_items = 1
                }
                else {
                    data.create_items = 0
                }
                if(data.view_items == true) {
                    data.view_items = 1
                }
                else {
                    data.view_items = 0
                }
                if(data.update_items == true) {
                    data.update_items = 1
                }
                else {
                    data.update_items = 0
                }
                if(data.delete_items == true) {
                    data.delete_items = 1
                }
                else {
                    data.delete_items = 0
                }
                if(data.export_items == true) {
                    data.export_items = 1
                }
                else {
                    data.export_items = 0
                }
            
            })
            axios.post(this.url, 
                {
                    request: 3,
                    user_group_id: this.user_group_option,
                    permissions: JSON.stringify(this.permission_results),
                    user_id: this.UserID
                })
                .then(response => {
                   
                    
                    console.log(response.data)
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        }
        
    },
    created() {
        this.show_user_group()
        this.show_permission()
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    }

};
</script>

<style>
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
