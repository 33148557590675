<template>
  <div class="home w-100">
    <!-- vvv content container vvv -->
    <div class="container">
      <div class="row body-container">
        <!-- ========== Content Card ================ -->
        <div class="col-12 p-0">
          <div class="body-card shadow rounded-2">
            <!-- vvv Content Go Here vvv -->
            <div class="position-relative">
              <div class="overlay-landing"></div>
              <div class="home-landing"><h1 class="text-center"><b>Welcome to Smart Legal Instrument</b></h1></div>
            </div>
            <!-- ^^^ Content Go Here ^^^ -->
          </div>             
        </div>    
        <!-- ========== Content Card END ================ -->          
      </div>   
    </div>
    <!-- ^^^ content container END ^^^ -->
    <div class="d-none">
      <!-- ========= KEEP THIS, IT WILL AFFECT THE HEADER POSITION IF DELETED ============= -->
      <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style>
    @import '../assets/css/body.css';
</style>
