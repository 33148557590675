<template>      
<div class="container">
    <div class="row body-container">
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <!-- ========== code start here  ========== -->

                <!-- ========== Title  ========== -->
                <div style="text-align:center" class="mb-4">
                    <h2>Testator Information</h2>
                </div>
                <!-- ========== User Profile  ========== -->              
                <div class="row mt-4">    
                    <!-- ========== Personal Info  ========== -->    
                    <div class="col-lg-4">
                        <div class="row g-2">
                            <div class="col-12">
                                <label for="name">Name:</label>
                                <input type="text" v-model="name">
                            </div>                                                                                
                            <div class="col-12">
                                <label for="name">Phone:</label>
                                <input type="text" v-model="phone">
                            </div>
                            <div class="col-12">
                                <label for="name">IC:</label>
                                <input type="text" v-model="ic">
                            </div>
                        </div>                    
                    </div>
                    <!-- ========== Address  ========== -->
                    <div class="col-lg-8">
                        <div class="row g-2"> 
                            <div class="col-lg-2">                                        
                                <label for="unit">Unit No.:</label>
                                <input type="text" v-model="unit_no">                                        
                            </div>                                    
                            <div class="col-lg-10">                                        
                                <label for="address">Address:</label>
                                <input type="text" v-model="address">                                        
                            </div>    
                            <div class="col-lg-6">                                        
                                <label for="postcode">Postcode:</label>
                                <input type="text" v-model="postcode">                                        
                            </div>           
                             <div class="col-lg-6">                                        
                                <label for="address">City:</label>
                                <input type="text" v-model="city">                                        
                            </div>   
                             <div class="col-lg-6">                                        
                                <label for="state">State:</label>
                                <input type="text" v-model="states">                                        
                            </div>                         
                            <div class="col-lg-6">                                        
                                <label for="country">Country:</label>
                                <input type="text" v-model="country">                                        
                            </div>      
                        </div>
                    </div>
                     <!-- ========== Remark  ========== -->
                    <div>
                         <label for="remark">Remark:</label>
                        <input type="text" v-model="remark">      
                    </div>
                    <!-- ========== Button and error  ========== -->
                    <div class="col-12">
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                            <v-btn color="green" dark @click="add_testator">
                                <span>Next</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <!-- ========== code end here  ========== -->
            </div>            
        </div>          
    </div>
</div>     
</template>

<script>
import axios from 'axios';
import store from'../../store';
export default {
    name: "CreateTestator",
    components: {

    },
    data() {

        return {
            name: "",
            phone: "",
            address: "",
            ic: "",
            unit_no:"",
            postcode:"",
            city:"",
            states:"",
            country:"",
            feedback: null,
            remark:"",
        };
    },
    methods: {

        add_testator() {
           if(this.name == "" ) {
                alert("Please input a name for testator !")
                return    
            }
            if(this.ic == "" ) {
                alert("Please input an Identity Card Number for testator !")
                return    
            }
            if(this.phone == "" ) {
                alert("Please input a phone number for testator !")
                return    
            }
            axios.post(this.url, 
                {
                    request: 2,
                    name: this.name,
                    phone: this.phone,
                    address: this.address,
                    ic: this.ic,
                    unit_no: this.unit_no,
                    postcode: this.postcode,
                    city: this.city,
                    states: this.states,
                    country: this.country,
                    user_id: this.UserID,
                    instrument_id: this.InstrumentID,
                    remark: this.remark
                })
                .then(response => {
                    if(response.data != "Error"){
                        store.commit("SearchTestator", response.data);
                    }
                    //alert(response.data)
                   
                    this.$router.push("/executors");
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },

        
        
    },
    created() {
 
        this.url = this.HostUrl + this.ScriptUrl + "testator.php"

    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        }

    }

};
</script>

<style>
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
