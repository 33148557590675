<template>
    <div class="footer-position">
        <v-footer class="justify-content-end d-none d-md-flex footer-bg">
            <div class="py-1">
                <small>&copy; {{ (new Date()).getFullYear() }} Lincoln Cybernetics Sdn Bhd (Company No.: 1000909-D)</small>
            </div>
        </v-footer>
        <v-footer class="justify-content-center d-flex d-md-none footer-bg">
            <div class="py-1">
                <small>&copy; {{ (new Date()).getFullYear() }} Lincoln Cybernetics Sdn Bhd (Company No.: 1000909-D)</small>
            </div>
        </v-footer>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            
        };
    },
    watch: {

    },
    methods: {
       
        

    },
    created() {
        
    },
    computed: {
       

    },

};
</script>

<style>

</style>
