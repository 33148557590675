<template>
    <div>
        <v-expansion-panels focusable>
            <!-- ========== Instrument Generator === -->
            <v-expansion-panel class="side-nav-menu">
                <v-expansion-panel-header>
                    <i class="bi bi-file-earmark-text-fill"></i>Instrument Generator
                </v-expansion-panel-header>
                <a>
                    <v-expansion-panel-content class="nav-side-option">
                        <v-list dense nav>
                            <v-list-item
                                v-for="instrument_item in instrument_items"
                                :key="instrument_item.title"
                                :to="instrument_item.link"
                                link
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ instrument_item.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ instrument_item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>                                                         
                    </v-expansion-panel-content>
                </a>       
            </v-expansion-panel>
            <v-expansion-panel class="side-nav-menu">
                <v-expansion-panel-header>
                    <i class="bi bi-person-fill"></i>Admin Access
                </v-expansion-panel-header> 
                 <a>
                    <v-expansion-panel-content class="nav-side-option">
                        <v-list dense nav>
                            <v-list-item
                                v-for="admin_item in admin_items"
                                :key="admin_item.title"
                                :to="admin_item.link"
                                link
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ admin_item.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ admin_item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          
                        </v-list>                                                         
                    </v-expansion-panel-content>
                </a>                                   
            </v-expansion-panel>
            <!-- ========== Instrument Generator END === -->
        </v-expansion-panels>
    </div>      
</template>

<script>


export default {
    name: "SideNavigationItems",
    data () {
      return {
        instrument_items: [
            { title: 'Generate New Instrument', icon: 'mdi-circle-small', link:'/create-instrument' },
            { title: 'Instrument Type', icon: 'mdi-circle-small', link:'/instrument-type' },
            { title: 'Relationship', icon: 'mdi-circle-small', link:'/relationship' },
            { title: 'Estate Type', icon: 'mdi-circle-small', link:'/estate-type' },
            { title: 'Search Testator', icon: 'mdi-circle-small', link:'/search-testator' },
 
        ],
        admin_items:[
            { title: 'User List', icon: 'mdi-circle-small', link:'/user-list' },
            { title: 'Permissions', icon: 'mdi-circle-small', link:'/permission' },
            { title: 'User Group', icon: 'mdi-circle-small', link:'/user-group' },
            { title: 'User Group Permissions', icon: 'mdi-circle-small', link:'/user-group-permission' },
       
        ],
        right: null,
      }
    },
    watch: {

    },
    methods: {
       
        

    },
    created() {
        
    },
    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    },

};
</script>

<style>
    @import '../assets/css/nav.css';
</style>
