<template>      
<div class="container">
    <div class="row body-container">
        <div style="text-align:center" class="my-4">
            <h2>Estate Information</h2>
        </div>
        <!-- ===== Add Button Card ===== -->
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height add-body-card" id="instrument-add-btn" style="cursor: pointer">
                <div class="btn-add d-flex align-items-center justify-content-center" @click="create_form">
                    <i class="bi bi-plus-circle-fill" ></i>              
                </div>
            </div>
        </div>
        <!-- ===== Add Button Card END ===== -->

        <!-- ===== Added Estate Info Card ===== -->
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3"  
            v-for="(all_estate_beneficiary_result, index) in all_estate_beneficiary_results"
                :key="index" >
            <div class="body-card shadow rounded-2 body-card-height p-4">            
                <h4 class="text-center"><b>{{ all_estate_beneficiary_result.name }}</b></h4>
                <hr>
                <div class="p-2">
                    <p><b>Estate Type :</b> {{ all_estate_beneficiary_result.estate_type_name }}</p>
                    <p class="remark-textbox"><b>Remark :</b> <span>{{ all_estate_beneficiary_result.remark }}</span></p> <!-- !!!!! Hide This if don't have remark  -->
                    <hr>
                    <!-- === beneficiary === -->
                    <div v-if="all_estate_beneficiary_result.estate_beneficiary_results!= 'empty'">
                        <div class="estate-preview-beneficiaries"  v-for="(beneficiary_data, index) in all_estate_beneficiary_result.estate_beneficiary_results"
                            :key="index" >
                            <p><b>Beneficiary :</b> {{ beneficiary_data.name }}</p>
                            <p><b>Proportion :</b> {{ beneficiary_data.proportion }}</p>
                            <p class="border p-2 rounded remark-textbox"><b>Beneficiary Remark :</b> <span>{{ beneficiary_data.remark }}</span></p> <!-- !!!!! Hide This if don't have remark  -->
                            <hr>
                        </div> 
                    </div>
                    <!-- === beneficiary END === -->                   
                </div>
                <div class="text-center mt-5">
                    <button class="btn btn-secondary instrument-edit-btn" @click="edit_estate(all_estate_beneficiary_result.ID)">Edit</button>
                    <button class="btn btn-danger ms-2" @click="delete_estate(all_estate_beneficiary_result.ID)">Delete</button>
                </div>          
            </div>
        </div>   
        <!-- ===== Added Estate Info Card END ===== -->    
        <!-- =================================================================================================================== -->
        <!-- ===== Add Form ===== -->    
        <div id="instrument-form" >
            <div class="instrument-form-overlay instrument-form-overlay-deactive"></div>
            <div class="instrument-form-modal instrument-form-modal-deactive">
                <div class="body-card shadow rounded-2">
                    <div class="row">
                        <!-- ===== title ===== -->
                        <div class="col-12 py-4 position-relative">
                            <h4 class="text-center"><b>Add Estate Information</b></h4>
                            <hr>
                            <div class="close-btn instrument-close-btn"><i class="bi bi-x-lg"></i></div>
                        </div>
                        <!-- ========== Add Estate Info  ========== --> 
                        <div class="col-12 col-md-6">
                            <label for="estate_name">Estate Name:</label>
                            <input type="text" v-model="estate_name">
                        </div>   
                        <div class="col-12 col-md-6">
                            <label for="estate_type">Estate Type:</label>
                            <select style="cursor: pointer;" v-model="estate_type" class="border-bottom border-secondary">
                                <option disabled value="">- Select Estate -</option>
                                <option v-for="(estate_type_result, index) in estate_type_results"
                                        :key="index" :value="estate_type_result.ID">
                                    {{ estate_type_result.name }}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label for="estate_remark">Remark:</label>
                            <input type="text" v-model="remark">
                        </div>    
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <v-btn color="green" dark @click="add_estate" v-if="!estate_added">
                                <span>Add Estate</span>
                            </v-btn>
                            <v-btn color="blue" dark @click="update_estate" v-if="estate_added">
                                <span>Update Estate</span>
                            </v-btn>
                        </div>
                        <!-- ========== Add Beneficiaries List  ========== --> 
                        <div v-if="estate_added">
                            <div class="col-12 mt-4">
                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="pe-4 col-12 col-lg-5 col-md-4 d-none d-md-block" style="opacity:.5"><hr class="w-25 ms-auto"></div>
                                    <div class="px-0 col-12 col-lg-2 col-md-4 text-center">Beneficiaries</div>
                                    <div class="ps-4 col-12 col-lg-5 col-md-4 d-none d-md-block" style="opacity:.5"><hr class="w-25 me-auto"></div>
                                </div>                    
                            </div>                         
                            <!-- ===================================================================== ADDED BENEFICIARIES -->
                            <!-- ==========  Added Beneficiaries List Start  ========== --> 
                            <div class="estate-beneficiary-list row py-4 my-1 mx-0 px-0 col-12 rounded-3" 
                                v-for="(estate_beneficiary_result, index) in estate_beneficiary_results"
                                    :key="index" :value="estate_beneficiary_result.ID">                            
                                <div class="estate-beneficiary-name col-md-8 col-12">
                                    <label for="beneficiary_name">Beneficiary Name:</label>
                                    <select style="cursor: pointer;" v-model="estate_beneficiary_result.beneficiary_ID" class="beneficiary-name border-bottom border-secondary">
                                        <option disabled value="">- Select Beneficiary -</option>
                                        <option v-for="(beneficiary_result, index) in beneficiary_results"
                                                :key="index" :value="beneficiary_result.ID">
                                            {{ beneficiary_result.name }}</option>
                                    </select>
                                </div>
                                <div class="estate-beneficiary-proportion col-md-2 col-12">
                                    <label for="proportion">Proportion:</label>
                                    <input type="text" v-model="estate_beneficiary_result.proportion">
                                </div>
                                <div class="estate-beneficiary-checkbox col-md-2 col-12">
                                    <label for="substitute">Substitute:</label>
                                    <div class="position-relative checkbox">
                                        <v-checkbox v-model="estate_beneficiary_result.subtitude"></v-checkbox>
                                        <!-- vvv Delete Button vvv -->
                                        <!-- <div class="estate-beneficiary-delete d-flex align-items-center justify-content-center rounded-3 shadow-sm"><i class="bi bi-trash-fill"></i></div> -->
                                    </div>                                
                                </div>
                                <div class="col-12 py-0 substitute-remark">
                                    <label for="substitute_remark" class="form-label">Remark</label>
                                    <textarea class="form-control substitute-remark-textbox" rows="3" v-model="estate_beneficiary_result.remark"></textarea>
                                </div>           
                                 
                                 <div class="d-flex align-items-center justify-content-center mt-5 mb-2">
                                     <div class="w-auto text-center">
                                        <v-btn class="m-1" color="blue" dark @click="update_estate_beneficiary(estate_beneficiary_result.ID, index)">
                                            <span>Update</span>
                                        </v-btn>
                                        <v-btn class="m-1" color="red" dark @click="delete_estate_beneficiary(estate_beneficiary_result.ID)">
                                            <span>Delete</span>
                                        </v-btn>
                                    </div>
                                 </div>
                                
                                <!-- <div class="col-12 mt-4" style="opacity:50%"><hr class="m-0 p-0 w-50 mx-auto"></div>                             -->
                            </div> 
                            <!-- ==========  Added Beneficiaries List End  ========== --> 
                           <!-- ===================================================================== ADD NEW BENEFICIARIES -->
                            <!-- ===== Add New Beneficiaries Box ===== -->
                            <div class="estate-beneficiary-list row py-4 my-1 mx-0 px-0 col-12 border border-dark rounded-3">                            
                                <div class="estate-beneficiary-name col-md-8 col-12">
                                    <label for="beneficiary_name">Beneficiary Name:</label>
                                    <select style="cursor: pointer;" v-model="beneficiary_name" class="beneficiary-name border-bottom border-secondary">
                                        <option disabled value="">- Select Beneficiary -</option>
                                        <option v-for="(beneficiary_result, index) in beneficiary_results"
                                                :key="index" :value="beneficiary_result.ID">
                                            {{ beneficiary_result.name }}</option>
                                    </select>
                                </div>
                                <div class="estate-beneficiary-proportion col-md-2 col-12">
                                    <label for="proportion">Proportion:</label>
                                    <input type="text" v-model="proportion">
                                </div>
                                <div class="estate-beneficiary-checkbox col-md-2 col-12">
                                    <label for="substitute">Substitute:</label>
                                    <div class="position-relative checkbox">
                                        <v-checkbox v-model="subtitude"></v-checkbox>
                                    </div>                                
                                </div>
                                <div class="col-12 py-0 substitute-remark">
                                    <label for="substitute_remark" class="form-label">Remark</label>
                                    <textarea class="form-control substitute-remark-textbox substitute_remark" rows="3" v-model="beneficiary_remark"></textarea>
                                </div>                            

                                <!-- vvv Add Beneficiary button  vvv --> 
                                <div class="col-12 my-0 py-4 text-center">
                                    <v-btn class="mx-1" color="blue-grey" dark  @click="add_estate_beneficiary">
                                        <span>Add Beneficiary</span>
                                    </v-btn>
                                </div>       
                            </div>                        
                            <!-- ===== Add New Beneficiaries Box END ===== -->            
                            <!-- ========== Add Beneficiaries List END  ========== -->       

                            <!-- ========== submit button  ========== -->   
                            <div class="col-12" style="opacity:1"><hr class="m-0 p-0"></div> 
                            <div class="center-align col-12 mt-5">
                                <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                                <v-btn color="green" dark @click="done_estate_beneficiary">
                                    <span>Done</span>
                                </v-btn>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ===== Add Form END ===== -->
        <!-- =================================================================================================================== -->
        <!-- ===== Edit Form ===== -->    
        <div id="instrument-edit-form">
            <div class="instrument-form-overlay instrument-form-overlay-deactive"></div>
            <div class="instrument-form-modal instrument-form-modal-deactive">
                <div class="body-card shadow rounded-2">
                    <div class="row">
                        <!-- ===== title ===== -->
                        <div class="col-12 py-4 position-relative">
                            <h4 class="text-center"><b>Edit Estate</b></h4>
                            <hr>
                            <div class="close-btn instrument-close-btn"><i class="bi bi-x-lg"></i></div>
                        </div>
                        <!-- ========== Edit Estate Info  ========== --> 
                        <div class="col-12 col-md-6">
                            <label for="estate_name">Estate Name:</label>
                            <input type="text" v-model="estate_name">
                        </div>   
                        <div class="col-12 col-md-6">
                            <label for="estate_type">Estate Type:</label>
                            <select style="cursor: pointer;" v-model="estate_type" class="border-bottom border-secondary">
                                <option disabled value="">- Select Estate -</option>
                                <option v-for="(estate_type_result, index) in estate_type_results"
                                        :key="index" :value="estate_type_result.ID">
                                    {{ estate_type_result.name }}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label for="estate_remark">Remark:</label>
                            <input type="text" v-model="remark">
                        </div> 

                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <v-btn color="blue" dark @click="update_estate" >
                                <span>Update Estate</span>
                            </v-btn>     
                        </div>
                              
                        <div class="col-12 mt-4">
                            <div class="row d-flex align-items-center justify-content-center">
                                <div class="pe-4 col-12 col-lg-5 col-md-4 d-none d-md-block" style="opacity:.5"><hr class="w-25 ms-auto"></div>
                                <div class="px-0 col-12 col-lg-2 col-md-4 text-center">Beneficiaries</div>
                                <div class="ps-4 col-12 col-lg-5 col-md-4 d-none d-md-block" style="opacity:.5"><hr class="w-25 me-auto"></div>
                            </div>                    
                        </div>                         
                        <!-- ========== Edit Beneficiaries List Start  ========== -->  

                        <!-- ===================================================================== EDIT ADDED BENEFICIARIES -->
                        <!-- ==========  Added Beneficiaries List Start  ========== --> 
                        <div class="estate-beneficiary-list row py-4 my-1 mx-0 px-0 col-12 rounded-3" 
                            v-for="(estate_beneficiary_result, index) in estate_beneficiary_results"
                                :key="index" :value="estate_beneficiary_result.ID">                            
                            <div class="estate-beneficiary-name col-md-8 col-12">
                                <label for="beneficiary_name">Beneficiary Name:</label>
                                <select style="cursor: pointer;" v-model="estate_beneficiary_result.beneficiary_ID" class="beneficiary-name border-bottom border-secondary">
                                    <option disabled value="">- Select Beneficiary -</option>
                                    <option v-for="(beneficiary_result, index) in beneficiary_results"
                                            :key="index" :value="beneficiary_result.ID">
                                        {{ beneficiary_result.name }}</option>
                                </select>
                            </div>
                            <div class="estate-beneficiary-proportion col-md-2 col-12">
                                <label for="proportion">Proportion:</label>
                                <input type="text" v-model="estate_beneficiary_result.proportion">
                            </div>
                            <div class="estate-beneficiary-checkbox col-md-2 col-12">
                                <label for="substitute">Substitute:</label>
                                <div class="position-relative checkbox">
                                    <v-checkbox v-model="estate_beneficiary_result.subtitude"></v-checkbox>
                                </div>                                
                            </div>
                            <div class="col-12 py-0 substitute-remark">
                                <label for="substitute_remark" class="form-label">Remark</label>
                                <textarea class="form-control substitute-remark-textbox" rows="3" v-model="estate_beneficiary_result.remark"></textarea>
                            </div>           
                                
                            <div class="d-flex align-items-center justify-content-center mt-5 mb-2">
                                <div class="w-auto text-center">
                                    <v-btn class="m-1" color="blue" dark @click="update_estate_beneficiary(estate_beneficiary_result.ID, index)">
                                        <span>Update</span>
                                    </v-btn>
                                    <v-btn class="m-1" color="red" dark @click="delete_estate_beneficiary(estate_beneficiary_result.ID)">
                                        <span>Delete</span>
                                    </v-btn>
                                </div>
                            </div>
                            <!-- <div class="col-12 mt-4" style="opacity:50%"><hr class="m-0 p-0 w-50 mx-auto"></div>                             -->
                        </div>                           

                        <!-- ===================================================================== EDIT ADD BENEFICIARY BOX -->                        
                        <div class="estate-beneficiary-list row py-4 my-1 mx-0 px-0 col-12 border border-dark rounded-3">                            
                            <div class="estate-beneficiary-name col-md-8 col-12">
                                <label for="beneficiary_name">Beneficiary Name:</label>
                                <select style="cursor: pointer;" v-model="beneficiary_name" class="beneficiary-name border-bottom border-secondary">
                                    <option disabled value="">- Select Beneficiary -</option>
                                    <option v-for="(beneficiary_result, index) in beneficiary_results"
                                            :key="index" :value="beneficiary_result.ID">
                                        {{ beneficiary_result.name }}</option>
                                </select>
                            </div>
                            <div class="estate-beneficiary-proportion col-md-2 col-12">
                                <label for="proportion">Proportion:</label>
                                <input type="text" v-model="proportion">
                            </div>
                            <div class="estate-beneficiary-checkbox col-md-2 col-12">
                                <label for="substitute">Substitute:</label>
                                <div class="position-relative checkbox">
                                    <v-checkbox v-model="subtitude"></v-checkbox>
                                </div>                                
                            </div>
                            <div class="col-12 py-0 substitute-remark">
                                <label for="substitute_remark" class="form-label">Remark</label>
                                <textarea class="form-control substitute-remark-textbox" rows="3" v-model="beneficiary_remark"></textarea>
                            </div>         
                            <!-- vvv Add Beneficiary button  vvv --> 
                            <div class="col-12 my-0 py-4 text-center">
                                <v-btn class="mx-1" color="blue-grey" dark  @click="add_estate_beneficiary">
                                    <span>Add Beneficiary</span>
                                </v-btn>
                            </div>             
                        </div>                        
                        
                        <!-- ========== submit button  ========== -->   
                        <div class="col-12" style="opacity:1"><hr class="m-0 p-0"></div> 
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                            <v-btn color="green" dark @click="done_estate_beneficiary">
                                <span>Save Edit</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ===== Edit Form END ===== -->    
    </div>          
    <br> 
       <!-- ===== Disposition of Residuary Estate Start ===== -->
    <div class="row body-container" id="residuary-estate">
        <hr>
        <br>
        <div class="col-12 d-flex align-items-center">
            <v-checkbox v-model="residuary_estate" style="display: inline-flex" class="residuary-checkbox"></v-checkbox>
            <span class="ps-2">Disposition of Residuary Estate </span>
        </div>        
        
        <div class="estate-beneficiary-list row py-4 my-1 mx-0 px-0 col-12 border border-dark rounded-3 residuary-disabled">                            
            <div class="estate-beneficiary-name col-md-8 col-12">
                <label for="beneficiary_name">Beneficiary Name:</label>
                <select style="cursor: pointer;" v-model="beneficiary_name_residuary" class="beneficiary-name border-bottom border-secondary">
                    <option disabled value="">- Select Beneficiary -</option>
                    <option v-for="(beneficiary_result, index) in beneficiary_results"
                            :key="index" :value="beneficiary_result.ID">
                        {{ beneficiary_result.name }}</option>
                </select>
            </div>
            <div class="estate-beneficiary-proportion col-md-2 col-12">
                <label for="proportion">Proportion:</label>
                <input type="text" v-model="proportion_residuary">
            </div>
            <div class="estate-beneficiary-checkbox col-md-2 col-12">
                <label for="substitute">Substitute:</label>
                <div class="position-relative checkbox">
                    <v-checkbox v-model="subtitude_residuary"></v-checkbox>
                </div>                                
            </div>
            <div class="col-12 py-0 substitute-remark">
                <label for="substitute_remark" class="form-label">Remark</label>
                <textarea class="form-control substitute-remark-textbox substitute_remark" rows="3" v-model="beneficiary_remark_residuary"></textarea>
            </div>                            

            <!-- vvv Add Beneficiary button  vvv --> 
            <div class="col-12 my-0 py-4 text-center">
                <v-btn class="mx-1" color="blue-grey" dark  @click="add_estate_beneficiary__residuary">
                    <span>Add Beneficiary to Residuary Estate</span>
                </v-btn>
            </div>       
        </div>            
    </div>
    <!-- ===================================================================== EDIT Residuary Estate Beneficiaries -->
    <!-- ==========  Added Residuary Estate Beneficiaries List Start  ========== --> 
    <div class="estate-beneficiary-list row py-4 my-1 mx-0 px-0 col-12 rounded-3" 
        v-for="(residuary_estate_beneficiary_result, index) in all_residuary_estate_beneficiary_results"
            :key="index" :value="residuary_estate_beneficiary_result.ID">                            
        <div class="estate-beneficiary-name col-md-8 col-12">
            <label for="beneficiary_name">Beneficiary Name:</label>
            <select style="cursor: pointer;" v-model="residuary_estate_beneficiary_result.beneficiary_ID" class="beneficiary-name border-bottom border-secondary">
                <option disabled value="">- Select Beneficiary -</option>
                <option v-for="(beneficiary_result, index) in beneficiary_results"
                        :key="index" :value="beneficiary_result.ID">
                    {{ beneficiary_result.name }}</option>
            </select>
        </div>
        <div class="estate-beneficiary-proportion col-md-2 col-12">
            <label for="proportion">Proportion:</label>
            <input type="text" v-model="residuary_estate_beneficiary_result.proportion">
        </div>
        <div class="estate-beneficiary-checkbox col-md-2 col-12">
            <label for="substitute">Substitute:</label>
            <div class="position-relative checkbox">
                <v-checkbox v-model="residuary_estate_beneficiary_result.subtitude"></v-checkbox>
            </div>                                
        </div>
        <div class="col-12 py-0 substitute-remark">
            <label for="substitute_remark" class="form-label">Remark</label>
            <textarea class="form-control substitute-remark-textbox" rows="3" v-model="residuary_estate_beneficiary_result.remark"></textarea>
        </div>           
            
        <div class="d-flex align-items-center justify-content-center mt-5 mb-2">
            <div class="w-auto text-center">
                <v-btn class="m-1" color="blue" dark @click="update_estate_beneficiary_residuary(residuary_estate_beneficiary_result.ID, index)">
                    <span>Update</span>
                </v-btn>
                <v-btn class="m-1" color="red" dark @click="delete_estate_beneficiary(residuary_estate_beneficiary_result.ID)">
                    <span>Delete</span>
                </v-btn>
            </div>
        </div>
    </div>                           

    <!-- ===================================================================== EDIT ADD BENEFICIARY BOX -->  
     <!-- ===== Disposition of Residuary Estate end ===== -->
    <div class="row border-top">
        <div class="col-12 text-center mt-5">
            <router-link :to="{ name: 'Beneficiary' }" style="text-decoration: none;">
                <v-btn color="green" dark>
                    <i class="bi bi-arrow-left-circle pe-1"></i><span>Back</span>
                </v-btn>
            </router-link>
                <v-btn color="green" dark @click="next_page" class="ms-2">
                    <span>Next</span><i class="bi bi-arrow-right-circle ps-1"></i>
                </v-btn>
        
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import store from "../../store";
export default {
    name: "Estate",
    components: {

    },
    data() {

        return {
            url: "",
            estate_name: "",
            remark: "",
            estate_type: "",
            estate_type_results: [],
            beneficiary_results: [],
            estate_beneficiary_results: [],
            all_estate_beneficiary_results: [],
            proportion: "",
            estate_added: false,
            beneficiary_name: "",
            subtitude: false,
            beneficiary_remark: "",
            feedback: "",
            estate_url: "",
            estate_beneficiary_url: "",
            beneficiary_name_residuary: "",
            subtitude_residuary: false,
            beneficiary_remark_residuary: "",
            proportion_residuary:"",
            residuary_estate: true,
            all_residuary_estate_beneficiary_results:[]
        };
    },
    methods: {

        create_form(){
            if(this.EstateID == ''){
                this.estate_added = false
                this.estate_name = ''
                this.estate_type = ''
                this.remark = ''
            }
        },
        // get estate type from database
        show_estate_type() {
            this.url = this.HostUrl + this.ScriptUrl + "estate_type.php"
            axios.post(this.url, 
                {
                    request: 1,
                }
                )
                .then(response => {
                    this.estate_type_results = response.data;
                   // console.log(response.data)
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        // get beneficiary from database
        show_beneficiary(){
            this.url = this.HostUrl + this.ScriptUrl + "beneficiary.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID
      
                }
                )
                .then(response => {
                    this.beneficiary_results = response.data;
                    this.beneficiary_results.forEach(function (data) {
                        data.proportion = 0
                        data.subtitude = false
                    });
    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        // all estate method
        // create estate 
        add_estate() {
            
            if(this.estate_name == "" ) {
                alert("Please input a name for the estate!")
                return    
            }
            if(this.estate_type == "" ) {
                alert("Please choose an estate type!")
                return    
            }
            axios.post(this.estate_url, 
                {
                    request: 2,
                    instrument_id: this.InstrumentID,
                    user_id: this.UserID,
                    estate_name: this.estate_name,
                    estate_type: this.estate_type,
                    remark: this.remark
                }
                )
                .then(response => {
                    //console.log(response.data)
                    this.estate_added = true
                    store.commit("EstateID", response.data);
                    console.log(response.data)
                    this.show_estate()
                    this.show_estate_beneficiary()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });

        },
        // when press edit in the card, edit form should pop up
        edit_estate(id) {
            store.commit("EstateID", id);
            this.show_estate()
            this.show_estate_beneficiary()
        },
        // update estate
        update_estate() {
            
            if(this.estate_name == "" ) {
                alert("Please input a name for the estate!")
                return    
            }
            if(this.estate_type == "" ) {
                alert("Please choose an estate type!")
                return    
            }
            axios.post(this.estate_url, 
                {
                    request: 3,
                    id: this.EstateID,
                    user_id: this.UserID,
                    estate_name: this.estate_name,
                    estate_type: this.estate_type,
                    remark: this.remark
                }
                )
                .then(response => {
                    console.log(response.data)
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        //get estate
        show_estate(){
       
            if(this.EstateID != '') {
                axios.post(this.estate_url, 
                {
                    request: 1,
                    id: this.EstateID
                   
                }
                )
                .then(response => {
                    //console.log(this.estate_name)
              
                    this.estate_name = response.data[0].name
                    console.log(this.estate_name)
                    this.estate_type = response.data[0].estate_type_ID
                    this.remark = response.data[0].remark
                    this.estate_added = true
                    //console.log(response.data)
                    this.show_estate_beneficiary()
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
            }
        },
        // delete estate
        delete_estate(id){
            Swal.fire({
                text: "Are you sure you want to delete this estate?",
                title: "Delete Estate",
                showCancelButton: true,
                type: "error",
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                confirmButtonColor:'green',
                cancelButtonColor:'red',
                showLoaderOnConfirm: true
            }).then((result) => {
                    if (result.value) {
                        axios.post(this.estate_url, 
                            {
                                request: 4,
                                id: id,
                                user_id: this.UserID
                            }
                            )
                            .then(response => {
                                store.commit("EstateID", "");
                                console.log(response.data)
                                this.show_all_estate_beneficiary()
                            })

                            .catch(function (error) {
                                console.log(JSON.stringify(error));
                                console.log("error response :" + error.response)
                                console.log("error status :" + error.status)
                            });
                    }
                }
            )
        },
        // all estate beneficiary method
        // create estate benficiary
        add_estate_beneficiary(){
            
            if(this.beneficiary_name == "" ) {
                alert("Please choose a benficiary!")
                return    
            }
            if(this.proportion == "" ) {
                alert("Please put a proportion for beneficiary!")
                return    
            }
          
            if(this.subtitude == true) {
                    this.subtitude = 1
            }
            else {
                this.subtitude = 0
            }
   
            
            axios.post(this.estate_beneficiary_url, 
                {
                    request: 2,
                    estate_id: this.EstateID,
                    beneficiary_id: this.beneficiary_name,
                    user_id: this.UserID,
                    subtitude: this.subtitude,
                    remark: this.beneficiary_remark,
                    proportion: this.proportion
                }
                )
                .then(response => {
                    console.log(response.data)
                    this.show_estate_beneficiary()
                    this.beneficiary_name = ''
                    this.proportion = ''
                    this.beneficiary_remark = ''
                    this.subtitude = false
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        // get estate beneficiary from database
        show_estate_beneficiary(){
            console.log(this.EstateID)
            axios.post(this.estate_beneficiary_url, 
                {
                    request: 1,
                    estate_id: this.EstateID
      
                }
                )
                .then(response => {
                    console.log(response.data)
                    if(response.data != ''){
                        this.estate_beneficiary_results = response.data;
                        this.estate_beneficiary_results.forEach(function (data) {
                            if(data.subtitude == 1) {
                                data.subtitude = true
                            }
                            else {
                                data.subtitude = false
                            }
                        });
                    }
                    else{
                        this.estate_beneficiary_results = []
                    }
                    })
                    .catch(function (error) {
                        console.log(JSON.stringify(error));
                        console.log("error response :" + error.response)
                        console.log("error status :" + error.status)
                    });
           
        },
        // update estate beneficiary
        update_estate_beneficiary(id, index){
            var beneficiary_id = this.estate_beneficiary_results[index].beneficiary_ID
            var subtitude = this.estate_beneficiary_results[index].subtitude
            var remark =  this.estate_beneficiary_results[index].remark
            var proportion =  this.estate_beneficiary_results[index].proportion
            if(beneficiary_id == "" ) {
                alert("Please choose a benficiary!")
                return    
            }
            if(proportion == "" ) {
                alert("Please put a proportion for beneficiary!")
                return    
            }
            if(subtitude == true) {
                subtitude = 1
            }
            else {
                subtitude = 0
            }   
            axios.post(this.estate_beneficiary_url, 
                {
                    request: 3,
                    id: id,
                    beneficiary_id: beneficiary_id,
                    user_id: this.UserID,
                    subtitude: subtitude,
                    remark: remark,
                    proportion: proportion
                }
                )
                .then(response => {
                    console.log(response.data)
                    this.show_estate_beneficiary()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        // after insert beneficiary into estate
        done_estate_beneficiary(){
            if(this.estate_beneficiary_results.length == 0) {
                alert("Please insert at least 1 beneficiary")
            }
            else {
                
                instrumentForm.closeForm('#instrument-edit-form')
                instrumentForm.closeForm('#instrument-form')
                store.commit("EstateID", "");
                this.show_all_estate_beneficiary()
                console.log(this.EstateID)
            }
        },
        show_all_estate_beneficiary(){
         
            axios.post(this.estate_beneficiary_url, 
                {
                    request: 5,
                    instrument_id: this.InstrumentID
                   
                }
                )
                .then(response => {
                    
                    this.all_estate_beneficiary_results = response.data
                  
                    console.log(response.data)
                   
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        // delete estate beneficiary
        delete_estate_beneficiary(id){
            Swal.fire({
                text: "Are you sure you want to delete this beneficiary from this estate?",
                title: "Delete Beneficiary from Estate",
                showCancelButton: true,
                type: "error",
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                confirmButtonColor:'green',
                cancelButtonColor:'red',
                showLoaderOnConfirm: true
            }).then((result) => {
                    if (result.value) {
                        axios.post(this.estate_beneficiary_url, 
                            {
                                request: 4,
                                id: id,
                                user_id: this.UserID
                            }
                            )
                            .then(response => {
                                
                                console.log(response.data)
                                this.show_estate_beneficiary()
                                this.show_all_estate_beneficiary()
                                this.show_estate_benificiary_residuary()
                            })

                            .catch(function (error) {
                                console.log(JSON.stringify(error));
                                console.log("error response :" + error.response)
                                console.log("error status :" + error.status)
                            });
                    }
                }
            )
        },
   
        
        // go to next page - guardian or declaration trust
        next_page(){
            var need_guardian = false
            this.beneficiary_results.forEach(function (data) {
                var today = new Date();
                var birthDate = new Date(data.dob);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
                {
                    age--;
                }
              
               
                
                if(parseInt(age) < 18){
                    need_guardian = true
                }
                
            });
            console.log(need_guardian)
            
            store.commit("EstateID", "")
            if(need_guardian == false) {
                Swal.fire({
                text: "Do you need guardian for your beneficiary?",
                title: "Guardians",
                showCancelButton: true,
                type: "error",
                confirmButtonText: 'Yes I need it!',
                cancelButtonText: "No, I don't it!",
                confirmButtonColor:'green',
                cancelButtonColor:'red',
                showLoaderOnConfirm: true
                }).then((result) => {
                        if (result.value) {
                            this.$router.push("/guardians");
                        }
                        else {
                            this.$router.push("/declarations-trusts");
                        }
                    }
                )
            }
            else {
                alert("There are some beneficiaries under 18.")
                this.$router.push("/guardians");
            }
            
        },
        //residuary estate
        add_estate_beneficiary__residuary(){
             if(this.beneficiary_name_residuary == "" ) {
                alert("Please choose a benficiary!")
                return    
            }
            if(this.proportion_residuary == "" ) {
                alert("Please put a proportion for beneficiary!")
                return    
            }
          
            if(this.subtitude_residuary == true) {
                    this.subtitude_residuary = 1
            }
            else {
                this.subtitude_residuary = 0
            }
            axios.post(this.estate_beneficiary_url, 
                {
                    request: 6,
                    user_id: this.UserID,
                    instrument_id: this.InstrumentID,
                    beneficiary_id: this.beneficiary_name_residuary,
                    subtitude: this.subtitude_residuary,
                    remark: this.beneficiary_remark_residuary,
                    proportion: this.proportion_residuary
                }
                )
                .then(response => {
                    
                    console.log(response.data)
                    this.show_estate_benificiary_residuary()
         
                })

                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_estate_benificiary_residuary(){
             axios.post(this.estate_beneficiary_url, 
                {
                    request: 7,
                    instrument_id: this.InstrumentID
                   
                }
                )
                .then(response => {
                    
                    this.all_residuary_estate_beneficiary_results = response.data
                    this.all_residuary_estate_beneficiary_results.forEach(function (data) {
                            if(data.subtitude == 1) {
                                data.subtitude = true
                            }
                            else {
                                data.subtitude = false
                            }
                        });
                    console.log(response.data)
                   
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        update_estate_beneficiary_residuary(id, index){
            var beneficiary_id = this.all_residuary_estate_beneficiary_results[index].beneficiary_ID
            var subtitude = this.all_residuary_estate_beneficiary_results[index].subtitude
            var remark =  this.all_residuary_estate_beneficiary_results[index].remark
            var proportion =  this.all_residuary_estate_beneficiary_results[index].proportion
            if(beneficiary_id == "" ) {
                alert("Please choose a benficiary!")
                return    
            }
            if(proportion == "" ) {
                alert("Please put a proportion for beneficiary!")
                return    
            }
            if(subtitude == true) {
                subtitude = 1
            }
            else {
                subtitude = 0
            }   
            console.log(subtitude)
            axios.post(this.estate_beneficiary_url, 
                {
                    request: 3,
                    id: id,
                    beneficiary_id: beneficiary_id,
                    user_id: this.UserID,
                    subtitude: subtitude,
                    remark: remark,
                    proportion: proportion
                }
                )
                .then(response => {
                    console.log(response.data)
                    this.show_estate_benificiary_residuary()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
    },
    created() {
        this.estate_url = this.HostUrl + this.ScriptUrl + "estate.php"
        this.estate_beneficiary_url = this.HostUrl + this.ScriptUrl + "estate_beneficiary.php"
        this.show_estate_type()
        this.show_beneficiary()
        this.residuary_estate = true
        this.show_estate()
        this.show_all_estate_beneficiary()
        this.show_estate_benificiary_residuary()
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        },
        EstateID(){
            return this.$store.state.estate_id;
        }

    },
    mounted(){
        instrumentForm.initAddForm()
        instrumentForm.setupCloseForm('#instrument-edit-form');     
        instrumentForm.checkResiduary();       
        instrumentForm.setupResiduary();
    },
    updated() {
        instrumentForm.setupEditBtn();
        instrumentForm.setupEstateBeneficiary();
    }
    
};
import {instrumentForm} from '../../assets/js/instrument-generator'
</script>

<style>
@import '../../assets/css/instrument-generator.css';
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
