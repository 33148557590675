<template>    
    <!-- <div class="col-12 pb-0"> -->
        <div class="nav-top">        
            <v-toolbar elevation="6">
                <div class="row">
                    <div class="col-4 d-block d-md-none"></div>
                    <div class="col-md-6 col-4 position-relative text-center">
                        <div class="nav-top-logo d-md-flex align-items-center d-none">
                            <a href="/" class="p-1">
                                <img src="../assets/images/logo/logo-main.png" alt="logo" draggable="false" style="max-height: 50px; max-width:220px">
                            </a>                        
                        </div>  
                        <!-- ===== MOBILE === -->
                        <div class="nav-top-logo d-flex align-items-center justify-content-center d-block d-md-none">
                            <a href="/" class="p-1">
                                <img src="../assets/images/logo/logo-main.png" alt="logo" draggable="false" style="max-height: 50px; max-width:220px">
                            </a>                        
                        </div>  
                        <!-- ===== MOBILE END === -->
                    </div>
                    <div class="col-4 col-md-6 position-relative">
                        <div class="nav-top-menu">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <button v-bind="attrs" v-on="on" class="rounded-3 nav-top-btn">
                                        <i class="bi bi-person-fill"></i>
                                    </button>
                                </template>
                                <v-list>
                                    <v-list-item
                                    v-for="(item, index) in items"
                                    :key="index"
                                    >
                                        <a @click="click_function(item.action)" style="display:flex">
                                            <v-icon class="me-2">{{ item.icon }}</v-icon>
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </a>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </div>
            </v-toolbar>
        </div>
    <!-- </div> -->
</template>

<script>
import store from "../store";
export default {
    name: "Header",
    // data() {
    //     return {
            
    //     };
    // },
    watch: {
        
    },
    methods: {
        click_function(action) {
           switch(action) {
            case 'logout':
                store.commit("IsLogin", false);
                store.commit("Username", "");
                store.commit("UserID", "");
                this.$router.push("/login");
            break;
            case 'profile':
                this.$router.push("/my-profile");
            break;

            }
        }

    },
    created() {
       // store.commit("HostUrl", 'https://admin.lincoln.com.my/');
        //store.commit("ScriptUrl", 'php_script/lincoln_will/');
    },
    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    },
    data: () => ({
      items: [
        { title: 'My Profile', icon: 'mdi-account-box',action: "profile" },
        { title: 'Sign Out', icon: 'mdi-logout', action: "logout" },
      ],
    }),

};
</script>

<style>
    @import '../assets/css/nav.css';
</style>
