<template>
<!-- vvv content container vvv -->
<div class="container">
    <div class="row body-container">        
        <!-- ========== Content Card ================ -->
        <div class="col-lg-4 p-0">
            <div class="body-card shadow rounded-2">
            <!-- vvv Content Go Here vvv -->
                <div style="text-align:center" class="mb-4">
                    <h2>My Profile Picture</h2>
                </div>
                <a @click="chooseFiles()" >
                    <div class="img-box rounded-3 mx-auto" >
                        <input id="fileUpload" type="file" @change="onFileSelected" ref="myFileInput" hidden>
                        <img v-if="profile_picture" :src="profile_picture_link" alt="No Image" class="rounded-3" draggable="false">
                        <img v-else src="../../assets/images/profile/no-image.png" alt="No Image" class="rounded-3" draggable="false">
                    </div>
                </a>
                <div class="text-center my-4">
                    <v-btn color="grey" dark @click="upload_picture"> 
                        <span>Upload Picture</span>
                    </v-btn>
                </div>
            <!-- ^^^ Content Go Here ^^^ -->
            </div>             
        </div>    
        <!-- ========== Content Card END ================ -->    
        <!-- ========== Content Card ================ -->
        <div class="col-lg-8 p-0">
            <div class="body-card shadow rounded-2">
            <!-- vvv Content Go Here vvv -->
                <div style="text-align:center" class="mb-4">
                    <h2>My Profile</h2>
                </div>
                <!-- ========= User Profile  -->
                <div v-for="(data, index) in results" :key="index">
                    <div class="row">
                        <div class="col-12">
                            <label for="name">Name:</label>
                            <input type="text" v-model="data.name">
                        </div>
                        <div class="col-lg-6">                                        
                            <label for="email">Email:</label>
                            <input type="text" v-model="data.email">                                        
                        </div>
                        <div class="col-lg-6">
                            <label for="password">Password:</label>           
                            <span class="position-relative">
                                <input :type="passwordFieldType" v-model="data.password">
                                <span class="show-password-icon">
                                    <v-btn small elevation="0" @click="switchVisibility()">
                                        <v-icon>remove_red_eye</v-icon>
                                    </v-btn>
                                </span>
                            </span>
                        </div>                                                            
                        <div class="col-lg-12">
                            <label for="name">Phone:</label>
                            <input type="text" v-model="data.phone">
                        </div>
                        <!-- ========== Button and error  ========== -->
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                            <v-btn color="green" dark @click ="edit_user(index)" >
                                <span>Update Profile</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            <!-- ^^^ Content Go Here ^^^ -->
            </div>             
        </div>    
        <!-- ========== Content Card END ================ -->              
    </div>
</div>   
<!-- ^^^ content container END ^^^ -->
</template>

<script>
import axios from 'axios';

export default {
    name: "UserList",
    components: {

    },
    data() {

        return {
            passwordFieldType: 'password',
            feedback: null,
            results: [], 
            selectedFile: null, 
            url: "" ,
            profile_picture: false,
            profile_picture_link: ""  
        };
    },
    methods: {
        chooseFiles() {
            document.getElementById("fileUpload").click()
        },
        onFileSelected(event) {
            this.selectedFile = event.target.files[0]
            
        },
        upload_picture(){
           // document.getElementById("fileUpload").click()
           console.log(this.selectedFile)
            let fd = new FormData();
            fd.append('file', this.selectedFile);
            fd.append('user_id',this.UserID)
            this.url = this.HostUrl + this.ScriptUrl + "upload_profile_picture.php"
            axios.post(this.url,
                    fd, 
                        {
                            headers: {'Content-Type': 'multipart/form-data'}, 
                        }
                ).then(response => {
                alert(response.data)
                    this.show_user()
                 })
                .catch(function () {
                    console.log('FAILURE!!');
                });
        },
        edit_user(index) {
            var name = this.results[index].name
            var email = this.results[index].email
            var password = this.results[index].password
            var user_group = this.results[index].user_group_ID
            var phone = this.results[index].phone
            var id = this.results[index].ID
            axios.post(this.url, 
            {
                request: 3,
                id: id,
                name: name,
                email: email,
                password: password,
                user_group: user_group,
                phone: phone,
                user_id: this.UserID
            })
            .then(response => {
                alert(response.data)
                this.show_user()
            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response)
                console.log("error status :" + error.status)
            });
        },

        show_user() {

            axios.post(this.url, 
                {
                    request: 1,
                    user_id: this.UserID
                }
                )
                .then(response => {
                    this.results = response.data;
                    console.log(response.data[0].profile_picture == null)
                    if(response.data[0].profile_picture !== null ){
                        this.profile_picture = true
                        this.profile_picture_link = this.HostUrl + "profile_pictures/" + response.data[0].profile_picture
                    }
                    if(response.data[0].profile_picture == "" ){
                        this.profile_picture = false
                    }
                    console.log(this.profile_picture)
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },

        
    },
    created() {
        
        this.url = this.HostUrl + this.ScriptUrl + "my_profile.php"
        this.show_user()
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    }

};
</script>

<style>
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
