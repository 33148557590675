const mobileSideNav = {
    async getTarget(target) {
        return await document.querySelector(target);
    },
    async addClass(target,targetClass) {
        const div = await document.querySelector(target);
        if(div){
            const gotClass = await div.classList.contains(targetClass);
            if(!gotClass){
                div.classList.add(targetClass);
            }
        }        
    },
    async removeClass(target,targetClass) {
        const div = await document.querySelector(target);
        if(div){
            const gotClass = await div.classList.contains(targetClass);
            if(gotClass){
                div.classList.remove(targetClass);
            }
        }        
    },    
    async setupClose() {
        const navSide = await this.getTarget('.mobile-nav-side');
        const btn = await this.getTarget('.mobile-nav-btn');
        const mobileNav = await this.getTarget('#mobile-nav');
        const navSideOpts = mobileNav.querySelectorAll('.side-nav-menu');
        for (const navSideOpt of navSideOpts) {
            const opts = navSideOpt.querySelectorAll('a');
            for (const opt of opts){
                opt.addEventListener('click',(e)=>{
                    if(opt.contains(e.target)){
                        this.addClass('.mobile-nav-bg', 'bg-deactive');
                        this.addClass('.mobile-nav-side', 'mobile-nav-deactive');
                    }
                });
            }            
        }
        mobileNav.addEventListener('click',(e)=>{
            if(!navSide.contains(e.target) && !btn.contains(e.target)){
                this.addClass('.mobile-nav-bg', 'bg-deactive');
                this.addClass('.mobile-nav-side', 'mobile-nav-deactive');
            }
        });
    },
    async setupShow() {
        const btn = await this.getTarget('.mobile-nav-btn');
        btn.addEventListener('click', ()=>{
            this.removeClass('.mobile-nav-bg', 'bg-deactive');
            this.removeClass('.mobile-nav-side', 'mobile-nav-deactive');
            this.setupClose()
        });
    },
    async setupMobileNav(){
        this.setupShow();        
    }
}        
const cssFunc = {
    async addTableClassByOne() {
        const getDiv = await document.querySelector('table');
        if (getDiv) {
            getDiv.classList.add('table','table-striped');
        }
    },
    async vbtnPosition() {
        const getDivs = await document.querySelectorAll('.v-btn-position');
        if (getDivs) {
            for (const getDiv of getDivs) {
                if (!getDiv.classList.contains('d-inline-table','pt-1')){
                    getDiv.classList.add('d-inline-table','pt-1');
                }                
                const vbtns = getDiv.querySelectorAll('button');
                for(const vbtn of vbtns) {
                    if (!vbtn.classList.contains('mb-1','me-1')) {
                        vbtn.classList.add('mb-1','me-1');
                    }
                }
            }
        }
    },
}

export {mobileSideNav,cssFunc};