<template>
  <v-app>
    <div>
      <div id="app" style="height: 100%;background-color: #f2f2f2; padding-bottom: 70px" class="d-flex" v-if="IsLogin">
            <Header />
            <SideNavigation />
            <SideNavigationMobile />
            <router-view style="height: 100%;"/>
            <Footer />
      </div>
      <div id="app" style="height: 100%;background-color: #f2f2f2;" class="d-flex" v-else>
            <router-view style="height: 100%;"/>
      </div>
    </div>
  </v-app>
</template>


<script>
import Header from '@/views/Header'
import Footer from '@/views/Footer'
import SideNavigation from '@/views/SideNavigation'
import SideNavigationMobile from '@/views/SideNavigationMobile'
export default {
  name: 'App',
  components: {
    SideNavigation,
    SideNavigationMobile,
    Header,
    Footer
    
  },
  computed: {
    IsLogin() {
      return this.$store.state.is_login;
    },
   
  }
}
</script>

<style>



</style>

