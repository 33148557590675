<template>   
<div class="container">
    <div class="row body-container">
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <!-- ========== code start here  ========== -->

                <!-- ========== Title  ========== -->
                <div style="text-align:center">
                    <h2>Relationship</h2>
                </div>
                <!-- ========== Table  ========== -->
                <div class="border px-2 rounded overflow-auto">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Remark</th>
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td><input type='text' v-model='name'></td>
                                <td><input type='text' v-model='remark'></td>
                                <td>
                                    <v-btn depressed color="green" dark @click="add_relationship">Add Relationship</v-btn>
                                </td>

                            </tr>
                    
                            <tr v-for="(data, index) in results" :key="index">
                                <td>{{ data.ID }}</td>
                                <td><input type='text' v-model='data.name'></td>
                                <td><input type='text' v-model='data.remark'></td>
                                <td class="v-btn-position">
                                    <v-btn depressed color="blue" dark @click="edit_relationship(index,data.ID)">Update </v-btn>
                                    <v-btn depressed color="red" dark @click="delete_relationship(data.ID)">Delete </v-btn>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>      
                <!-- ========== code end here  ========== -->
            </div>            
        </div>          
    </div>    
</div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
    name: "UserList",
    components: {

    },
    data() {

        return {
            results: [],
            show: false,
            remark: "",
            name: "",
            url: ""
        };
    },
    methods: {
        add_relationship() {
            if(this.name == "" ) {
                alert("Please input a name into relationship !")
                return    
            }
            axios.post(this.url, 
                {
                    request: 2,
                    name: this.name,
                    remark: this.remark,
                  
                    user_id: this.UserID
                })
                .then(response => {
                    this.name = ''
                    this.remark = ''
                    this.password = ''
                    this.permission_group = ''
                    this.phone = ''
                    console.log(response.data)
                    this.show_relationship()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        edit_relationship(index, id) {
            var name = this.results[index].name
            var remark = this.results[index].remark
            if(name == '' ) {
                alert("Please input a name into relationship !")
                return    
            }
            axios.post(this.url, 
            {
                request: 3,
                id:id,
                name: name,
                remark: remark,
                user_id: this.UserID
            })
            .then(response => {
                console.log(response.data)
                this.show_relationship()
            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response)
                console.log("error status :" + error.status)
            });
        },

        show_relationship() {

            axios.post(this.url, 
                {
                    request: 1,
                }
                )
                .then(response => {
                    this.results = response.data;
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        delete_relationship(id) {
            Swal.fire({
                text: "Are you sure you want to delete this relationship?",
                title: "Delete Relationship",
                showCancelButton: true,
                type: "error",
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                confirmButtonColor:'green',
                cancelButtonColor:'red',
                showLoaderOnConfirm: true
            }).then((result) => {
                    if (result.value) {
                        axios.post(this.url, 
                        {
                            request: 4,
                            id: id,
                            user_id: this.UserID
                        }
                        )
                        .then(response => {
                            
                            alert(response.data)
                            this.show_relationship()
                        })
                        
                        .catch(function (error) {
                            console.log(JSON.stringify(error));
                            console.log("error response :" + error.response)
                            console.log("error status :" + error.status)
                        });
                    }
                }
            )
           
        },
    },
    created() {
        
        this.url = this.HostUrl + this.ScriptUrl + "relationship.php"
        this.show_relationship()
    },
    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    },

    mounted() {
        
    },
    updated() {
        cssFunc.vbtnPosition();
    }

};
import {cssFunc} from '../../assets/js/custom'
</script>

<style>
@import '../../assets/css/body.css';
select {
    display: grid;
}



</style>
