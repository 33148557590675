<template>
<div class="container">
    <div class="row body-container">
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <!-- ========== code start here  ========== -->

                <!-- ========== Title  ========== -->
                <div style="text-align:center" class="mb-4">
                    <h2>User List</h2>
                </div>
                <!-- ========== Table  ========== -->
                <div class="border px-2 rounded overflow-auto">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Password</th>
                                <th>Phone</th>
                                <th>User Group</th>
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td><input type='text' v-model='name'></td>
                                <td><input type='email' v-model='email'></td>
                                <td><input type='text' v-model='password'></td>
                                <td><input type='text' v-model='phone'></td>
                                <td>
                                    <select v-model='user_group'>
                                        <option value="" disabled>
                                            Please Select One
                                        </option>
                                        <option value="1">
                                            IT Admin
                                        </option>
                                        <option value="2">
                                            Lincoln office Admin
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <v-btn depressed color="green" dark @click="add_user">Add User</v-btn>
                                </td>

                            </tr>
                    
                            <tr class="d-talbe-cell" v-for="(data, index) in results" :key="index">
                                <td>{{ data.ID }}</td>
                                <td><input type='email' v-model='data.name'></td>
                                <td><input type='text' v-model='data.email'></td>
                                <td><input type='text' v-model='data.password'></td>
                                <td><input type='text' v-model='data.phone'></td>
                                <td> 
                                    <select v-model='data.user_group_ID'>
                                        <option value="1">
                                            IT Admin
                                        </option>
                                        <option value="2">
                                            Lincoln office Admin
                                        </option>
                                    </select>
                                </td>                                
                                <td class="v-btn-position">
                                    <v-btn class="" depressed color="blue" dark @click="edit_user(index,data.ID)">Update </v-btn>
                                    <v-btn class="" depressed color="red" dark @click="delete_user(data.ID)">Delete </v-btn>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>      
                <!-- ========== code end here  ========== -->
            </div>            
        </div>   
    </div>
</div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
    name: "UserList",
    components: {

    },
    data() {

        return {
            results: [],
            show: false,
            email: "",
            name: "",
            password: "",
            user_group:"",
            phone:"",
            url: ""
        };
    },
    methods: {
        add_user() {
            if(this.password == "") {
                this.password = Array(10).fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*())_+~[]<>/?,.").map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
            }
            axios.post(this.url, 
                {
                    request: 2,
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    user_group: this.user_group,
                    phone: this.phone,
                    user_id: this.UserID
                })
                .then(response => {
                    this.name = ''
                    this.email = ''
                    this.password = ''
                    this.user_group = ''
                    this.phone = ''
                    alert(response.data)
                    this.show_user()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        edit_user(index, id) {
            var name = this.results[index].name
            var email = this.results[index].email
            var password = this.results[index].password
            var user_group = this.results[index].user_group_ID
            var phone = this.results[index].phone
            axios.post(this.url, 
            {
                request: 3,
                id:id,
                name: name,
                email: email,
                password: password,
                user_group: user_group,
                phone: phone,
                user_id: this.UserID
            })
            .then(response => {
                alert(response.data)
                this.show_user()
            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response)
                console.log("error status :" + error.status)
            });
        },

        show_user() {

            axios.post(this.url, 
                {
                    request: 1,
                }
                )
                .then(response => {
                    this.results = response.data;
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        delete_user(id) {
            Swal.fire({
                text: "Are you sure you want to delete this user?",
                title: "Delete User",
                showCancelButton: true,
                type: "error",
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                confirmButtonColor:'green',
                cancelButtonColor:'red',
                showLoaderOnConfirm: true
            }).then((result) => {
                    if (result.value) {
                       axios.post(this.url, 
                        {
                            request: 4,
                            id: id,
                            user_id: this.UserID,
                        }
                        )
                        .then(response => {
                            
                            alert(response.data)
                            this.show_user()
                        })
                        
                        .catch(function (error) {
                            console.log(JSON.stringify(error));
                            console.log("error response :" + error.response)
                            console.log("error status :" + error.status)
                        });
                    }
                }
            )
        },
    },
    created() {
        
        this.url = this.HostUrl + this.ScriptUrl + "user_list.php"
        this.show_user()
    },


    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        }

    },
    updated() {
        cssFunc.vbtnPosition();
    },
    
};
import {cssFunc} from '../../assets/js/custom'
</script>

<style>
@import '../../assets/css/body.css';
select {
    display: grid;
}



</style>
