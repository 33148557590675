<template>
  <div class="col-12 p-0">
    <div class="login-bg">
      <div class="container w-100 position-relative h-100">
        <div class="login z-depth-3 rounded-2 position-relative" id="login-card">
          <v-btn dark color="white" class="position-absolute back-button mt-4" @click="back" style="color:black; height:60px; width:50px;transform: scale(.8);top:0;left:10px">
            <i class="bi bi-backspace-fill" style="font-size:20px"></i>
          </v-btn>
            <div class="row">
              <div class="col-12 text-center">
                <h2><b>
                  Reset Password
                </b></h2>
              </div>
              <div class="col-12">
                <div class="w-75 mx-auto">
                  <label style="" for="email">Email Address:</label>
                  <input type="email" id="email" v-model="email">
                </div>                
              </div>
              <div class="col-12">
                <div class="w-75 mx-auto text-center">
                  <p
                    v-if="feedback"
                    class="red-text"
                  >{{ feedback }}</p>
                  <button @click="reset" type="submit" class="btn1 btn-large btn-extended grey lighten-4 black-text my-1 rounded-2">Reset</button>
                </div>                
              </div>              
            </div>           
        </div>
        <div class="row">
            <div class="col-12">
                <div class="text-center text-light">
                    <small id="login-copyright">Lincoln Cybernetics Sdn Bhd (Company No.: 1000909-D)</small>
                </div>
            </div>
        </div>          
      </div>        
    </div>
  </div>      
</template>
<script>
//import store from "../store";
import axios from 'axios';

export default {
  data() {
    return {
        email: "",
        feedback:null,
        datas: [],
        url:""
    };
  },
  methods: {
    back(){
      this.$router.push("/login");
    } ,
    reset() {
        if( this.email == "") {
            this.feedback = "Please enter an email address"
            return
        }

            axios.post(this.url, {
                email: this.email,
            })
            .then(response => {
                console.log(response.data);
                
                if(response.data != "Error") {
                    alert(response.data)
                    this.$router.push("/login");
                }
                
                
                
            })
            .catch(function (error) {
                console.log(error);
            });
        
    }
        
  
  },
  created(){
    this.url = this.HostUrl + this.ScriptUrl + "forget_password.php"
  },
  computed: {
       HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        RememberBoolean(){
            return this.$store.state.remember_boolean;
        },
        RememberUsername(){
            return this.$store.state.remember_username;
        },
        RememberPassword(){
            return this.$store.state.remember_password;
        }
  }
};
</script>

<style>
</style>