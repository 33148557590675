<template>      
<div class="container">
    <div class="row body-container">
        <!-- <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                ---- CONTENT -----
            </div>
        </div> -->

        <!-- ====== Declaration ===== -->    
        <div style="text-align:center" class="my-4 col-12">
            <h2>Declaration</h2>
        </div>  
        <div class="col-12 col-md-6">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <div id="declaration-inheritance-refusal">
                    <div class="col-12 dnt-check">
                        <v-checkbox v-model="inheritance_refusal" label="Declaration (Inheritance Refusal)"></v-checkbox>
                    </div>
                    <div class="p-2 border rounded dnt-box dnt-box-disabled">
                        <div class="col-12">
                            <label for="inheritance_refusal_name">Name:</label>
                            <input type="text" v-model="inheritance_refusal_name" :disabled="!inheritance_refusal">
                        </div>
                        <div class="col-12">
                            <label for="inheritance_refusal_ic">IC:</label>
                            <input type="text" v-model="inheritance_refusal_ic" :disabled="!inheritance_refusal">
                        </div>
                        <div class="col-12">
                            <label for="inheritance_refusal_relationship">Relationship:</label>
                            <select style="cursor: pointer;" v-model="inheritance_refusal_relationship" >
                                            <option disabled value="">Please select one</option>
                                            <option v-for="(relationship_result, index) in relationships"
                                                    :key="index" :value="relationship_result.ID">
                                                {{ relationship_result.name }}</option>
                            </select>
                            <v-divider></v-divider>
                        </div>                     
                    </div>      
                </div>  
            </div>
        </div>
        <!-- ----- -->
        <div class="col-12 col-md-6">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <div id="declaration-marry-soon">
                    <div class="col-12 dnt-check">
                        <v-checkbox v-model="marry_soon" label="Declaration (In view if Getting Married Soon)"></v-checkbox>
                    </div>       
                    <div class="p-2 border rounded dnt-box dnt-box-disabled">
                        <div class="col-12">
                            <label for="marry_soon_name">Name:</label>
                            <input type="text" v-model="marry_soon_name" :disabled="!marry_soon">
                        </div>
                        <div class="col-12">
                            <label for="marry_soon_ic">IC:</label>
                            <input type="text" v-model="marry_soon_ic" :disabled="!marry_soon">
                        </div>
                    </div>    
                </div>
            </div>
        </div>        
        <!-- ====== Declaration END ===== --> 
         <div class="col-12 border-top text-center" style="margin-top:3rem">
             <v-btn class="mt-4" color="blue" dark @click="update_declaration">
                <span>Update Declaration</span>
            </v-btn>     

            
        </div>                    
               
        <!-- ====== Trust ===== -->
        <div style="text-align:center; margin-top: 2rem" class="col-12">
            <hr>
            <h2 style="margin-top: 2rem">Trust Information</h2>
        </div>          
        <!-- ===== Add Button Card ===== -->
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height add-body-card" id="instrument-add-btn" style="cursor: pointer">
                <div class="btn-add d-flex align-items-center justify-content-center">
                    <i class="bi bi-plus-circle-fill"></i>              
                </div>
            </div>
        </div>
        <!-- ===== Add Button Card END ===== -->        
        <!-- ===== Trust Preview ===== -->       
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-for="(trust_result, index) in trust_results"
                                    :key="index" :value="trust_result.ID">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                <div class="border p-2 rounded">
                    <div class="my-4">
                        <b>Trust Name:</b>
                        <p>{{ trust_result.name }}</p>
                    </div>
                    <hr style="opacity:.25">
                    <div class="my-4">
                        <b>Beneficiaries</b>
                        <p v-for="(beneficiary_data, index) in trust_result.trust_beneficiary_results"
                                    :key="index" >{{ beneficiary_data.name }}</p>
                    </div>
                    <hr style="opacity:.25">
                    <div class="my-4">
                        <b>Where does the trust fund come from?</b>
                        <p>{{ trust_result.source_fund }}</p>
                    </div>
                    <hr style="opacity:.25">
                    <div class="my-4">
                        <b>How should the trust fund be spent?</b>
                        <p>{{ trust_result.purpose }}</p>
                    </div>
                    <hr style="opacity:.25">
                    <div class="my-4">
                        <b>At which point the trust is expired?</b>
                        <p>{{ trust_result.expire }}</p>
                    </div>
                    <hr style="opacity:.25">
                    <div class="my-4">
                        <b>How to dispose the residuary trust fund, if any?</b>
                        <p>{{ trust_result.dispose }}</p>
                    </div>
                </div>
                <div class="text-center mt-5">
                    <button class="btn btn-secondary instrument-edit-btn" @click="edit_trust(trust_result.ID)">Edit</button>
                    <button class="btn btn-danger ms-2" @click="delete_trust(trust_result.ID)">Delete</button>
                </div>
            </div>
        </div>
        <!-- ===== Trust Preview END ===== -->   

        <!-- ===== Trust Form ===== -->    
        <div id="instrument-form">
            <div class="instrument-form-overlay instrument-form-overlay-deactive"></div>
            <div class="instrument-form-modal instrument-form-modal-deactive">
                <div class="body-card shadow rounded-2">
                    <div class="row">
                        <!-- ===== title ===== -->
                        <div class="col-12 py-4 position-relative">
                            <h4 class="text-center"><b>Add Trust</b></h4>
                            <hr>
                            <div class="close-btn instrument-close-btn"><i class="bi bi-x-lg"></i></div>
                        </div>
                        <!-- ========== Personal Info  ========== --> 

                        <div class="col-12">
                            <label for="trust_name">Trust Name:</label>
                            <input type="text" v-model="trust_name">
                        </div>
                        <div class="col-12">
                            <label for="trust_name">Beneficiary Name:</label>
                             <v-select style="cursor: pointer;" v-model="trust_beneficiary" multiple 
                             :items="beneficiary_results" 
                              item-text="name"
                              item-value="ID"
                              class="beneficiary-name border-bottom border-secondary">
                            </v-select>
                        </div>
                        <div class="col-12">
                            <label for="trust_source">Where does the trust fund come from?</label>
                            <input type="text" v-model="trust_source">
                        </div>
                        <div class="col-12">
                            <label for="trust_purpose">How should the trust fund be spent?</label>
                            <input type="text" v-model="trust_purpose">
                        </div> 
                        <div class="col-12">
                            <label for="trust_expire">At which point the trust is expired?</label>
                            <input type="text" v-model="trust_expire">
                        </div>           
                        <div class="col-12">
                            <label for="trust_dispose">How to dispose the residuary trust fund, if any?</label>
                            <input type="text" v-model="trust_dispose">
                        </div>     
                        <div class="col-12">
                            <label for="trust_remark">Remarks:</label>
                            <input type="text" v-model="trust_remark">
                        </div>     
                        <!-- ========== submit button  ========== -->   
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                            <v-btn color="green" dark @click="add_trust">
                                <span>Add</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ===== Trust Form END ===== -->
        
        <!-- ===== Edit Form ===== -->    
        <div id="instrument-edit-form">
            <div class="instrument-form-overlay instrument-form-overlay-deactive"></div>
            <div class="instrument-form-modal instrument-form-modal-deactive">
                <div class="body-card shadow rounded-2">
                    <div class="row" v-for="(current_trust_result, index) in current_trust_results"
                                    :key="index" >
                        <!-- ===== title ===== -->
                        <div class="col-12 py-4 position-relative">
                            <h4 class="text-center"><b>Edit Trust</b></h4>
                            <hr>
                            <div class="close-btn instrument-close-btn"><i class="bi bi-x-lg"></i></div>
                        </div>
                        <!-- ========== Personal Info  ========== --> 

                        <div class="col-12">
                            <label for="trust_name">Trust Name:</label>
                            <input type="text" v-model="current_trust_result.name">
                        </div>
                        <div class="col-12">
                            <label for="trust_name">Beneficiary Name:</label>
                            <v-select style="cursor: pointer;" v-model="current_trust_result.trust_beneficiary_results" multiple 
                             :items="beneficiary_results" 
                              item-text="name"
                              item-value="ID"
                              class="beneficiary-name border-bottom border-secondary">
                            </v-select>
                        </div>
                        <div class="col-12">
                            <label for="trust_name">Where does the trust fund come from?</label>
                            <input type="text" v-model="current_trust_result.source_fund">
                        </div>
                        <div class="col-12">
                            <label for="trust_name">How should the trust fund be spent?</label>
                            <input type="text" v-model="current_trust_result.purpose">
                        </div> 
                        <div class="col-12">
                            <label for="trust_name">At which point the trust is expired?</label>
                            <input type="text" v-model="current_trust_result.expire">
                        </div>           
                        <div class="col-12">
                            <label for="trust_name">How to dispose the residuary trust fund, if any?</label>
                            <input type="text" v-model="current_trust_result.dispose">
                        </div>     
                        <div class="col-12">
                            <label for="trust_remark">Remark</label>
                            <input type="text" v-model="current_trust_result.remark">
                        </div>    

                        <!-- ========== submit button  ========== -->   
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                            <v-btn color="green" dark @click="update_trust(current_trust_result.ID,index)">
                                <span>Save Edit</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ===== Edit Form END ===== -->
        
        <!-- ====== Trust END ===== -->

        <div class="col-12 border-top text-center" style="margin-top:3rem"> 
            
            <v-btn color="green" dark class="mt-4" @click="back">
                <i class="bi bi-arrow-left-circle pe-1"></i><span>Back</span>
            </v-btn>
            <v-btn class="ms-2 mt-4" color="green" dark @click="generate_draft">
                <span>Generate Draft</span>
            </v-btn>     
        </div>                    
               
    </div>     
</div>
</template>

<script>
import axios from 'axios';
import { instrumentForm } from '../../assets/js/instrument-generator';
import store from "../../store";
export default {
    name: "Estate",
    components: {

    },
    data() {

        return {
            url: "",
            trust:"",
            marry_soon:false,
            inheritance_refusal:false,
            trust_beneficiary:"",
            trust_dispose:"",
            trust_expire:"",
            trust_purpose:"",
            trust_name:"",
            trust_source:"",
            trust_remark:"",
            marry_soon_name:"",
            marry_soon_ic:"",
            inheritance_refusal_name:"",
            inheritance_refusal_ic:"",
            inheritance_refusal_relationship:"",
            relationships:[],
            feedback:"",
            beneficiary_results:[],
            trust_results:[],
            current_trust_results:[]
        };
    },
    methods: {
        back(){
            history.back()
        },
        onchangeInheritanceRefusal(){
            if(this.inheritance_refusal == false) {
                this.inheritance_refusal_name = ""
                this.inheritance_refusal_ic = ""
                this.inheritance_refusal_relationship = ""
            }
            
        },
        onchangeMarrySoon(){
            if(this.marry_soon == false) {
                this.marry_soon_name = ""
                this.marry_soon_ic = ""
            }
            
        },
        update_declaration(){
            this.url = this.HostUrl + this.ScriptUrl + "declaration.php"
            axios.post(this.url, 
                {
                    request: 2,
                    marry_soon_name: this.marry_soon_name,
                    marry_soon_ic: this.marry_soon_ic,
                    inheritance_refusal_name: this.inheritance_refusal_name,
                    inheritance_refusal_ic: this.inheritance_refusal_ic,
                    inheritance_refusal_relationship: this.inheritance_refusal_relationship,
                    user_id: this.UserID,
                    instrument_id: this.InstrumentID,
                    marry_soon: this.marry_soon,
                    inheritance_refusal: this.inheritance_refusal
                }
                )
                .then(response => {
                    console.log(response.data)
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        show_declartion(){
            this.url = this.HostUrl + this.ScriptUrl + "declaration.php"
            //inheritance refusal data
             axios.post(this.url, 
                {
                    request: 3,
                    instrument_id: this.InstrumentID,
                }
                )
                .then(response => {
                    
                    if(response.data.length > 0){
                        this.inheritance_refusal = true
                        this.inheritance_refusal_name = response.data[0]['name']
                        this.inheritance_refusal_ic = response.data[0]['ic']
                        this.inheritance_refusal_relationship = response.data[0]['relationship_ID']
                    }
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
            //marry soon data
            this.url = this.HostUrl + this.ScriptUrl + "declaration.php"
            axios.post(this.url, 
                {
                    request: 4,
                    instrument_id: this.InstrumentID,
                }
                )
                .then(response => {
                    
                    if(response.data.length > 0){
                        this.marry_soon = true
                        this.marry_soon_name = response.data[0]['name']
                        this.marry_soon_ic = response.data[0]['ic']
                    }
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        
        add_trust(){
            this.url = this.HostUrl + this.ScriptUrl + "trust.php"
            axios.post(this.url, 
                {
                    request: 2,
                    trust_beneficiary: JSON.stringify(this.trust_beneficiary),
                    trust_dispose: this.trust_dispose,
                    trust_expire: this.trust_expire,
                    trust_purpose: this.trust_purpose,
                    trust_name: this.trust_name,
                    trust_source: this.trust_source,
                    trust_remark: this.trust_remark,
                    user_id: this.UserID,
                    instrument_id: this.InstrumentID,
                    
                }
                )
                .then(response => {
                    console.log(response.data)
                    this.trust_dispose = ''
                    this.trust_expire = ''
                    this.trust_purpose = ''
                    this.trust_name = ''
                    this.trust_source = ''
                    this.trust_remark = ''
                    this.show_trust()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
       
        },
        show_trust(){
            this.url = this.HostUrl + this.ScriptUrl + "trust.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID,
                    
                }
                )
                .then(response => {
                    this.trust_results = response.data
                   // console.log(response.data)
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                }); 
        },
        edit_trust(id){
            this.url = this.HostUrl + this.ScriptUrl + "trust.php"
            axios.post(this.url, 
                {
                    request: 5,
                    id: id,
                }
                )
                .then(response => {
                    this.current_trust_results = response.data
                    console.log(this.current_trust_results)
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                }); 
        },
        update_trust(id,index){
            this.url = this.HostUrl + this.ScriptUrl + "trust.php"
            var trust_beneficiary = this.current_trust_results[index].trust_beneficiary_results
            var trust_dispose = this.current_trust_results[index].dispose
            var trust_expire = this.current_trust_results[index].expire
            var trust_purpose = this.current_trust_results[index].trust_purpose
            var trust_name = this.current_trust_results[index].name
            var trust_source = this.current_trust_results[index].source_fund
            var trust_remark = this.current_trust_results[index].remark

            axios.post(this.url, 
                {
                    request: 3,
                    id: id,
                    trust_beneficiary: JSON.stringify(trust_beneficiary),
                    trust_dispose: trust_dispose,
                    trust_expire: trust_expire,
                    trust_purpose: trust_purpose,
                    trust_name: trust_name,
                    trust_source: trust_source,
                    trust_remark: trust_remark,
                    user_id: this.UserID,
                    
                }
                )
                .then(response => {
                    console.log(response.data)
                    instrumentForm.closeForm('#instrument-edit-form')
                    this.show_trust()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                }); 
        },
        delete_trust(id){
            this.url = this.HostUrl + this.ScriptUrl + "trust.php"
            axios.post(this.url, 
                {
                    request: 4,
                    id: id,
                    user_id:this.UserID
                }
                )
                .then(response => {
                    console.log(response.data)
                    this.show_trust()
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                }); 
        }, 
        show_relationship(){
            this.url = this.HostUrl + this.ScriptUrl + "relationship.php"
            axios.post(this.url, 
                {
                    request: 1,
                }
                )
                .then(response => {
                    this.relationships = response.data;
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_beneficiary(){
            this.url = this.HostUrl + this.ScriptUrl + "beneficiary.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID
      
                }
                )
                .then(response => {
                    this.beneficiary_results = response.data;
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        generate_draft(){
            store.commit("DraftName", "First Draft");
            this.$router.push("/instrument-editor");
        }
        
    },
    created() {
        this.show_relationship()
        this.show_beneficiary()
        this.show_declartion()
        this.show_trust()
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        },
        DraftName(){
             return this.$store.state.draft_name;
        }

    },
    mounted(){
        instrumentForm.setupCheckbox('#declaration-inheritance-refusal','dnt-box-disabled');   
        instrumentForm.setupCheckbox('#declaration-marry-soon','dnt-box-disabled');           
        instrumentForm.initAddForm();
        instrumentForm.setupCloseForm('#instrument-edit-form');
    },
    updated() {
        instrumentForm.setupEditBtn()
        instrumentForm.scanCheckbox('#declaration-inheritance-refusal','dnt-box-disabled');   
        instrumentForm.scanCheckbox('#declaration-marry-soon','dnt-box-disabled');
    }

};

</script>

<style>
@import '../../assets/css/instrument-generator.css';
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
