<template>
  <div class="home w-100">
    <!-- vvv content container vvv -->
    <div class="container">
      <div class="row body-container">
        <!-- ========== Content Card ================ -->
        <div class="col-12 p-0">
          <div class="body-card shadow rounded-2">           
            <div class="row">
                <div class="col-12">
                    <div style="text-align:center" class="mb-4">
                        <h2>Instrument Editor</h2>
                        <hr>
                    </div>
                </div>
                <div class="col-12 pt-0">
                    <div class="w-50 mx-auto">
                        <input class="border rounded-3 px-4 text-center fw-bolder placeholder-color" type="text" v-model="draft_name" placeholder="Insert Instrument Name" style="background:var(--grey-2);">
                    </div>
                </div>
                <div class="col-12">
                    <Vue2WangEditor v-model="content" />             
                </div>
                <div class="col-12 text-center">
                    <v-btn color="success" class="px-4" @click="save">
                        Save
                    </v-btn>
                </div>
            </div>           
          </div>             
        </div>    
        <!-- ========== Content Card END ================ -->          
      </div>   
    </div>
    <!-- ^^^ content container END ^^^ -->
    
  </div>
</template>

<script>
import axios from 'axios';
import Vue2WangEditor from "vc-wangeditor";
import { instrumentForm } from '../../assets/js/instrument-generator';

export default {
    name: "InstrumentDraft",
    components: {
  
        Vue2WangEditor,
    },
    data() {

        return {
            reference_code: "",
            results:[],
            content: '',
            feedback: "",
            draft_name: "Draft",
            url: ""
          
        };
    },
    methods: {
       save(){
           //console.log(this.content)

           axios.post(this.url, 
                {
                    request: 3,
                    name: this.draft_name,
                    instrument_id: this.InstrumentID,
                    content: this.content,
                    user_id: this.UserID

                }
                )
                .then(response => {
                    alert(response.data)
                    this.$router.push("/search-testator")
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
       },
       get_content(){
           
           this.draft_name = this.DraftName
           var request = ""
           if(this.draft_name == "First Draft") {
               request = 2
           }
           else{
               request = 4
           }
           
        
           axios.post(this.url, 
                {
                    request: request,
                    draft_id: this.DraftID,
                    instrument_id: this.InstrumentID

                }
                )
                .then(response => {
                    this.content = response.data
                    //console.log(response.data)
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
             
       },     
    },
    created() {
        
        this.url = this.HostUrl + this.ScriptUrl + "draft.php"
        this.get_content()
        console.log(this.DraftID)
        console.log(this.DraftName)
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        },
        DraftName(){
             return this.$store.state.draft_name;
        },
        DraftID(){
            return this.$store.state.draft_id;
        }


    },
    mounted(){
        instrumentForm.adjustEditor();
    }

};
</script>

<style>
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';

@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
} 
    

  
  @media print {
     

    body  
    { 
        /* this affects the margin on the content before sending to printer */ 
        margin: 0;
    } 
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }
  
    html, body {
      width: 210mm;
      height: 297mm;
      margin-bottom: 100mm;
    }
  }

  /* ===== Custom CSS =========== */
.will-header{
    margin: 50px 0;
    text-align: center;
    padding: 50px;
    width: 100%;
    border-top: solid 2px black;
    border-bottom: solid 2px black;
}
.will-b{
    font-weight: bold;
}
.will-bi{
    font-weight: bold;
    font-style: italic;
}
.will-section-title{
    font-weight: bold;
    text-decoration: underline;
    margin-top: 50px;
}
.pdf-footer{
    padding-top: 200px;
}
.placeholder-color::placeholder{
    color: rgb(0, 0, 0);
    opacity: .15;
}

</style>
