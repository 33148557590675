<template>
<div class="container">
    <div class="row body-container">
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <!-- ========== code start here  ========== -->

                <!-- ========== Title  ========== -->
                <div style="text-align:center" class="mb-4">
                    <h2>Instrument Drafts</h2>
                </div>
                <!-- ========== Table  ========== -->
                <div class="border px-2 rounded overflow-auto">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Draft Name</th>
                                <th>Author Updated</th>
                                <th>Date Created</th>
                                <th>Date Updated</th>
           
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>

                    
                            <tr class="d-talbe-cell" v-for="(data, index) in results" :key="index">
                                <td>{{ data.ID }}</td>
                                <td>{{ data.name }}</td>
                                <td>{{ data.updated_by_name }}</td>
                                <td>{{ data.created_at }}</td>
                                <td>{{ data.updated_at }}</td>

                                <td class="v-btn-position">
                                    <v-btn class="" depressed color="blue" dark @click="edit_content(data.ID,data.name)">Edit </v-btn>
                                    <v-btn class="" depressed color="green" dark @click="save_content(data.ID)">Save Content</v-btn>
                                    <v-btn class="" depressed color="blue-grey" dark @click="save_cover()">Save Cover</v-btn>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>      
                <!-- ========== code end here  ========== -->
                 <div v-html="content" class="page-break-line">

                 </div>
            </div>            
        </div>   
    </div>
</div>
</template>

<script>
import axios from 'axios';
//import Swal from 'sweetalert2'
import store from "../../store";
export default {
    name: "InstrumentDraft",
    components: {

    },
    data() {

        return {
            results: [],
            show: false,
            email: "",
            name: "",
            password: "",
            user_group:"",
            phone:"",
            url: "",
            content:"",
            content_print: false
        };
    },
    methods: {
        show_draft() {
            axios.post(this.url, 
                {
                    request: 1,
                    //draft_id: this.DraftID,
                    instrument_id: this.InstrumentID

                }
                )
                .then(response => {
                    this.results = response.data
                    console.log(response.data)
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        save_cover(){
            
     
            
            axios.post(this.url, 
                {
                    request: 5,
                    instrument_id: this.InstrumentID,
                }
                )
                .then(response => {
                    this.content = response.data
                    //console.log(this.content)
                    this.printdiv()
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
                
        },
        save_content(id){
            axios.post(this.url, 
                {
                    request: 4,
                    draft_id: id,
                }
                )
                .then(response => {
                    this.content = response.data
                    this.printdiv()
                   
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
            
        },
        printdiv()
        {
            var headstr = "<html><head><title></title></head><body>";
            var footstr = "</body>";
    
            var oldstr = document.body.innerHTML;
    
            document.body.innerHTML = headstr+this.content+footstr;
            setTimeout(function(){ 

                window.print();
               document.body.innerHTML = oldstr;
                 window.location.reload();
            }, 1000);  
            console.log(document.body.innerHTML)
            return false;
            
        },

        edit_content(id,name){
            store.commit("DraftName", name);
            store.commit("DraftID",id);
                               
            this.$router.push("/instrument-editor");
        }
        
    },
    created() {
        this.url = this.HostUrl + this.ScriptUrl + "draft.php"
        this.show_draft()
        this.content = '<h1> Hello </h1>';
    },


    computed: {
         Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        },
        DraftName(){
             return this.$store.state.draft_name;
        },
        DraftID(){
            return this.$store.state.draft_id;
        }


    },
    updated() {
        cssFunc.vbtnPosition();
    },
    
};
import {cssFunc} from '../../assets/js/custom'
</script>

<style>
@import '../../assets/css/body.css';
    select {
        display: grid;
    }

    @page {
    size: A4;
    margin: 11mm 17mm 17mm 17mm;
    } 
    
    .page-break-line{
    /**Make sure you don't give any height,border. Because giving them gives an 
    extra page.
    */
    visibility: hidden;
    }


  
  @media print {
    .page-break-line {
        page-break-before: always !important;
    }
    body  
    { 
        /* this affects the margin on the content before sending to printer */ 
        margin: 0;
    } 
    p {
        -webkit-column-break-inside: avoid; 
        page-break-inside: avoid; 
        break-inside: avoid;
    }
    .content-group {
        -webkit-column-break-inside: avoid; 
        page-break-inside: avoid; 
        break-inside: avoid;
    }
    table thead {
        height: 0px;
    }    
    html, body {
        width: 210mm;
        height: 297mm;
         margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
        
    }
    .empty-footer {
        height:25mm;
    }
    .footer {
        position: fixed;
        width: 100%;
        bottom:0
    }

  }

  /* ===== Custom CSS =========== */
.will-header{
    margin: 50px 0;
    text-align: center;
    padding: 50px;
    width: 100%;
    border-top: solid 2px black;
    border-bottom: solid 2px black;
}
.will-b{
    font-weight: bold;
}
.will-bi{
    font-weight: bold;
    font-style: italic;
}
.will-section-title{
    font-weight: bold;
    text-decoration: underline;
    margin-top: 50px;
}
.pdf-footer{
    padding-top: 200px;
}
#printcover{
    position: relative;
    overflow: hidden;
    width: 210mm;
    height: 297mm;
}
#cover-page{
    position: absolute;
    width: 210mm;
    height: 297mm;
    top: 0;
    left: 0;    
    overflow: hidden;
}
#cover-page img{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
#cover-page .testator{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 150%);
}


</style>
