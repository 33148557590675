<template>      
<div class="container">
    <div class="row body-container">
        <div style="text-align:center" class="my-4">
            <h2>Guardians Information</h2>
        </div>
        <!-- +++++++++ BODY CARD TEMPLATES +++++++++ -->
        <!-- <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height p-4">
                ---- CONTENT -----
            </div>
        </div> -->
        <!-- +++++++++ BODY CARD TEMPLATES END +++++++++ -->

        <!-- ===== Add Button Card ===== -->
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <div class="body-card shadow rounded-2 body-card-height add-body-card" id="instrument-add-btn" style="cursor: pointer">
                <div class="btn-add d-flex align-items-center justify-content-center">
                    <i class="bi bi-plus-circle-fill"></i>              
                </div>
            </div>
        </div>
        <!-- ===== Add Button Card END ===== -->

        <!-- ===== Added guardians ===== -->

        <!-- Sample Info  -->
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-for="(data, index) in results" :key="index">
            <div class="body-card shadow rounded-2 body-card-height p-4"> 
                <h4 class="text-center"><b>{{ data.name }}</b></h4>
                <hr>
                <div class="p-2">
                    <p><b>Phone :</b> {{ data.phone }}</p>
                    <p><b>IC :</b> {{ data.ic }}</p>
                    <p><b>Address :</b> {{ data.address }}</p>
                    <p><v-checkbox v-model="data.joint" label="Joint"></v-checkbox></p>
                    <p><v-checkbox v-model="data.subtitude" label="Subtitude"></v-checkbox></p>
                    <p><b>Unit No. :</b> {{ data.unit_no }}</p>
                    <p><b>Postcode :</b> {{ data.postcode }}</p>
                    <p><b>City :</b> {{ data.city }}</p>
                    <p><b>State :</b> {{ data.states }}</p>
                    <p><b>Country :</b> {{ data.country}}</p>
                    <p><b>Relationship :</b> {{ data.relationship_name }}</p>
                </div>
                <div class="text-center mt-5">
                    <button class="btn btn-secondary instrument-edit-btn" @click="show_current_guardians(data.ID)">Edit</button>
                    <button class="btn btn-danger ms-2" @click="delete_guardians(data.ID)">Delete</button>
                </div>
            </div>
        </div>   
        <!-- Sample Info END -->

        
        <!-- ===== Added guardians END ===== -->     

        <!-- ===== guardian Form ===== -->    
        <div id="instrument-form">
            <div class="instrument-form-overlay instrument-form-overlay-deactive"></div>
            <div class="instrument-form-modal instrument-form-modal-deactive">
                <div class="body-card shadow rounded-2">
                    <div class="row">
                        <!-- ===== title ===== -->
                        <div class="col-12 py-4 position-relative">
                            <h4 class="text-center"><b>Add guardian</b></h4>
                            <hr>
                            <div class="close-btn instrument-close-btn"><i class="bi bi-x-lg"></i></div>
                        </div>
                        <!-- ========== Personal Info  ========== --> 
                        <div class="col-12">
                            <label for="name">Relationship:</label>
                            <select style="cursor: pointer;" v-model="relationship" >
                                <option disabled value="">Please select one</option>
                                <option v-for="(relationship_result, index) in relationships"
                                        :key="index" :value="relationship_result.ID">
                                    {{ relationship_result.name }}</option>
                            </select>
                            <v-divider></v-divider>
                        </div>
                        <div class="col-12">
                            <label for="name">Name:</label>
                            <input type="text" v-model="name">
                        </div>                                                                                
                        <div class="col-md-6">
                            <label for="name">Phone:</label>
                            <input type="text" v-model="phone">
                        </div>
                        <div class="col-md-6">
                            <label for="name">IC:</label>
                            <input type="text" v-model="ic">
                        </div>
                         <br>
                            <div class="col-md-6">
                                <v-checkbox v-model="joint" label="Joint"></v-checkbox>
                            </div>
                             <div class="col-md-6">
                                <v-checkbox v-model="subtitude" label="Subtitude"></v-checkbox>
                            </div>
                        <br>
                        <!-- ========== Address  ========== -->   
                        <div class="col-12">                                        
                            <label for="unit">Unit No.:</label>
                            <input type="text" v-model="unit_no">                                        
                        </div>                                    
                        <div class="col-12">                                        
                            <label for="address">Address:</label>
                            <input type="text" v-model="address">                                        
                        </div>    
                        <div class="col-12  col-md-6">                                        
                            <label for="postcode">Postcode:</label>
                            <input type="text" v-model="postcode">                                        
                        </div>           
                        <div class="col-12  col-md-6">                                        
                            <label for="address">City:</label>
                            <input type="text" v-model="city">                                        
                        </div>   
                        <div class="col-12  col-md-6">                                        
                            <label for="state">State:</label>
                            <input type="text" v-model="states">                                        
                        </div>                         
                        <div class="col-12  col-md-6">                                        
                            <label for="country">Country:</label>
                            <input type="text" v-model="country">                                        
                        </div>
                        <!-- ========== submit button  ========== -->   
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                            <v-btn color="green" dark @click="add_guardians">
                                <span>Add</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ===== guardian Form END ===== -->
        
        <!-- ===== Edit Form ===== -->    
        <div id="instrument-edit-form">
            <div class="instrument-form-overlay instrument-form-overlay-deactive"></div>
            <div class="instrument-form-modal instrument-form-modal-deactive">
                <div class="body-card shadow rounded-2" v-for="(current_guardians_result, index) in current_guardians_results" :key="index">
                    <div class="row">
                        <!-- ===== title ===== -->
                        <div class="col-12 py-4 position-relative">
                            <h4 class="text-center"><b>Edit guardian</b></h4>
                            <hr>
                            <div class="close-btn instrument-close-btn"><i class="bi bi-x-lg"></i></div>
                        </div>
                        <!-- ========== Personal Info  ========== --> 
                        <div class="col-12">
                            <label for="name">Relationship:</label>
                            <select style="cursor: pointer;" v-model="current_guardians_result.relationship_ID" >
                                <option disabled value="">Please select one</option>
                                <option v-for="(relationship_result, index) in relationships"
                                        :key="index" :value="relationship_result.ID">
                                    {{ relationship_result.name }}</option>
                            </select>
                            <v-divider></v-divider>
                        </div>
                        <div class="col-12">
                            <label for="name">Name:</label>
                            <input type="text" v-model="current_guardians_result.name">
                        </div>                                                                                
                        <div class="col-md-6">
                            <label for="name">Phone:</label>
                            <input type="text" v-model="current_guardians_result.phone">
                        </div>
                        <div class="col-md-6">
                            <label for="name">IC:</label>
                            <input type="text" v-model="current_guardians_result.ic">
                        </div>
                        <br>
                             <div class="col-md-6">
                                <v-checkbox v-model="current_guardians_result.joint" label="Joint"></v-checkbox>
                            </div>
                             <div class="col-md-6">
                                <v-checkbox v-model="current_guardians_result.subtitude" label="Subtitude"></v-checkbox>
                            </div>
                        <br>
                        <!-- ========== Address  ========== -->   
                        <div class="col-12">                                        
                            <label for="unit">Unit No.:</label>
                            <input type="text" v-model="current_guardians_result.unit_no">                                        
                        </div>                                    
                        <div class="col-12">                                        
                            <label for="address">Address:</label>
                            <input type="text" v-model="current_guardians_result.address">                                        
                        </div>    
                        <div class="col-12  col-md-6">                                        
                            <label for="postcode">Postcode:</label>
                            <input type="text" v-model="current_guardians_result.postcode">                                        
                        </div>           
                        <div class="col-12  col-md-6">                                        
                            <label for="address">City:</label>
                            <input type="text" v-model="current_guardians_result.city">                                        
                        </div>   
                        <div class="col-12  col-md-6">                                        
                            <label for="state">State:</label>
                            <input type="text" v-model="current_guardians_result.states">                                        
                        </div>                         
                        <div class="col-12  col-md-6">                                        
                            <label for="country">Country:</label>
                            <input type="text" v-model="current_guardians_result.country">                                        
                        </div>
                        <!-- ========== submit button  ========== -->   
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                            <v-btn color="green" dark @click="update_guardians">
                                <span>Save Edit</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ===== Edit Form END ===== -->     
    </div>
    <!-- ======== Next Page =========== -->
    <div class="row border-top">
        <div class="col-12 text-center mt-5">
            <router-link :to="{ name: 'Estate' }" style="text-decoration: none;">
                <v-btn color="green" dark> 
                    <i class="bi bi-arrow-left-circle pe-1"></i><span>Back</span>
                </v-btn>
            </router-link>
            <router-link :to="{ name: 'DeclarationTrust' }" style="text-decoration: none;">
                <v-btn color="green" dark class="ms-2">
                    <span>Next</span><i class="bi bi-arrow-right-circle ps-1"></i>
                </v-btn>
            </router-link>
        </div>
    </div>
</div>     
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
    name: "Guardian",
    components: {

    },
    data() {

        return {
            name: "",
            phone: "",
            address: "",
            ic: "",
            unit_no:"",
            postcode:"",
            city:"",
            states:"",
            country:"",
            relationship:"",
            feedback: null,
            results:[],
            relationships:[],
            current_guardians_results:[],
            joint: false,
            subtitude: false,
        };
    },
    methods: {

        add_guardians() {
            
           if(this.name == "" ) {
                alert("Please input a name for guardian !")
                
                return    
            }
            if(this.ic == "" ) {
                alert("Please input an Identity Card Number for guardian !")
                return    
            }
            if(this.phone == "" ) {
                alert("Please input a phone number for guardian !")
                return    
            }
            this.url = this.HostUrl + this.ScriptUrl + "guardian.php"
            if(this.subtitude == true) {
                this.subtitude = 1
            }
            else {
                this.subtitude = 0
            }
             if(this.joint == true) {
                this.joint = 1
            }
            else {
                this.joint = 0
            }
            axios.post(this.url, 
                {
                    request: 2,
                    name: this.name,
                    phone: this.phone,
                    address: this.address,
                    ic: this.ic,
                    unit_no: this.unit_no,
                    postcode: this.postcode,
                    city: this.city,
                    states: this.states,
                    country: this.country,
                    user_id: this.UserID,
                    instrument_id: this.InstrumentID,
                    relationship_id: this.relationship,
                    joint: this.joint,
                    subtitude: this.subtitude
                })
                .then(response => {
                    console.log(response.data)
                    
                    //alert(response.data)
                   instrumentForm.closeForm('#instrument-form')
               
                    this.name = "" 
                    this.phone = ""
                    this.address = ""
                    this.ic = ""
                    this.unit_no = ""
                    this.postcode = ""
                    this.city = ""
                    this.states = ""
                    this.country = ""
                    this.relationship = ""
                    this.show_guardians()
                    
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_guardians(){
            this.url = this.HostUrl + this.ScriptUrl + "guardian.php"
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID
                }
                )
                .then(response => {

                    this.results = response.data;
                    this.results.forEach(function (data) {
                        if(data.joint == 1) {
                            data.joint = true
                        }
                        else {
                            data.joint = false
                        }
                        if(data.subtitude == 1) {
                            data.subtitude = true
                        }
                        else {
                            data.subtitude = false
                        }
                   
                    })
                    //console.log(response.data)
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_relationship(){
            this.url = this.HostUrl + this.ScriptUrl + "relationship.php"
            axios.post(this.url, 
                {
                    request: 1,
                }
                )
                .then(response => {
                    this.relationships = response.data;
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        delete_guardians(id){
            this.url = this.HostUrl + this.ScriptUrl + "guardian.php"
            Swal.fire({
                text: "Are you sure you want to delete this guardian?",
                title: "Delete Guardian",
                showCancelButton: true,
                type: "error",
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                confirmButtonColor:'green',
                cancelButtonColor:'red',
                showLoaderOnConfirm: true
            }).then((result) => {
                    if (result.value) {
                        axios.post(this.url, 
                        {
                            request: 4,
                            id: id,
                            user_id: this.UserID
                        }
                        )
                        .then(response => {
                            
                            console.log(response.data)
                            this.show_guardians()
                        })
                        
                        .catch(function (error) {
                            console.log(JSON.stringify(error));
                            console.log("error response :" + error.response)
                            console.log("error status :" + error.status)
                        });
                    }
                }
            )
            
        },
        show_current_guardians(id) {
            this.url = this.HostUrl + this.ScriptUrl + "guardian.php"
            axios.post(this.url, 
                {
                    request: 5,
                    id: id,
                    
                }
                )
                .then(response => {
                    console.log(response.data)
                    this.current_guardians_results = response.data;
                     this.current_guardians_results.forEach(function (data) {
                        if(data.joint == 1) {
                            data.joint = true
                        }
                        else {
                            data.joint = false
                        }
                        if(data.subtitude == 1) {
                            data.subtitude = true
                        }
                        else {
                            data.subtitude = false
                        }
                   
                    })
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        update_guardians(){
            var index = 0
            var name = this.current_guardians_results[index].name
            var phone = this.current_guardians_results[index].phone
            var address =  this.current_guardians_results[index].address
            var ic = this.current_guardians_results[index].ic   
            var unit_no = this.current_guardians_results[index].unit_no
            var postcode = this.current_guardians_results[index].postcode
            var city = this.current_guardians_results[index].city
            var states = this.current_guardians_results[index].states
            var country = this.current_guardians_results[index].country
            var relationship_id = this.current_guardians_results[index].relationship_ID
            var id = this.current_guardians_results[index].ID
            var subtitude = this.current_guardians_results[index].subtitude
            var joint = this.current_guardians_results[index].joint
            if(subtitude == true) {
                subtitude = 1
            }
            else {
                subtitude = 0
            }
             if(joint == true) {
                joint = 1
            }
            else {
                joint = 0
            }

            if(name == "" ) {
                alert("Please input a name for guardian !")
                return    
            }    
            if(ic == "" ) {
                alert("Please input an Identity Card Number for guardian !")
                return    
            }
            if(phone == "" ) {
                alert("Please input a phone number for guardian !")
                return    
            }
            axios.post(this.url, 
            {
                request: 3,
                id:id,
                name: name,
                phone: phone,
                address: address,
                ic: ic,
                unit_no: unit_no,
                postcode: postcode,
                city: city,
                states: states,
                country: country,
                relationship_id: relationship_id,
                user_id: this.UserID,
                subtitude: subtitude,
                joint:joint
            })
            .then(response => {
               console.log(response.data)
                instrumentForm.closeForm('#instrument-edit-form')
                this.show_guardians()
            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response)
                console.log("error status :" + error.status)
            });
        }

        
        
    },
    created() {
 
        this.url = this.HostUrl + this.ScriptUrl + "guardian.php"
        this.show_relationship()
        this.show_guardians()
     
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        }

    },
    mounted(){
        instrumentForm.initAddForm()
        instrumentForm.setupCloseForm('#instrument-edit-form');
    },
    updated() {
        instrumentForm.setupEditBtn()
    }

};
import {instrumentForm} from '../../assets/js/instrument-generator'
</script>

<style>
@import '../../assets/css/instrument-generator.css';
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
