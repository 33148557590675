<template>
  <div class="home w-100">
    <!-- vvv content container vvv -->
    <div class="container">
      <div class="row body-container">
        <!-- ========== Content Card ================ -->
        <div class="col-12 p-0 d-flex align-items-center justify-content-center">
          <div class="body-card shadow rounded-2">
            <!-- Search function here  -->
            <div style="text-align:center" class="mb-4">
                <h2>Search Testator</h2>
            </div>
            <div class="border rounded-2 p-2">
                <label for="name">Testator Name:</label>
                <v-autocomplete
                    v-model="testator"
                    :items="testators"
                    item-text="name"
                    item-value="ID"
                >
                    
                </v-autocomplete>
                <v-divider></v-divider>
 
            </div>
            <div class="col-12">
                <div class="center-align col-12">
                    <p v-if="feedback" class="red-text">{{ feedback }}</p>                                    
                    <v-btn color="green" dark @click="search_testator">
                        <span>Search</span>
                    </v-btn>
                </div>
            </div>
            <!-- ^^^ Search function end here^^^ -->
          </div>             
        </div>    
        <!-- ========== Content Card END ================ -->     
        <!-- ===================================================================================================== -->
        <!-- ========== Search Result ================ -->  
        <div v-if="results.length>0" >
            <div class="col-12 col-sm-12 col-lg-4 col-xl-3" v-for="(result, index) in results" :key="index" >
                <div class="body-card shadow rounded-2 body-card-height p-4">
                    <div class="row">            
                        <div class="col-12">
                            <label for="name">Name:</label>
                            {{ result.name }}
                        
                        </div>                                                                                
                        <div class="col-md-6">
                            <label for="name">Phone:</label>
                            {{ result.phone }}
                        </div>
                        <div class="col-md-6">
                            <label for="name">IC:</label>
                            {{ result.ic }}
                        </div>      

                        <div class="col-12"><hr></div><!-- =================== -->

                        <div class="col-12 text-center mt-0 pt-0">
                            <b>Instrument Details</b>
                        </div>
                        <!-- ==========Add testator ================ -->  
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-8)" @click="update_testator(result.instrument_ID)">  <!-- <<==== Testator Button  -->
                                <i class="bi bi-person-rolodex"></i>                      
                            </div>
                            <small>Testator</small>                                                              
                        </div>

                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-1)" @click="executor(result.instrument_ID)">  <!-- <<==== Executor Button  -->
                                <i class="bi bi-person-circle"></i>                       
                            </div>
                            <small>Executor</small>                                                              
                        </div>
                        
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-2)" @click="beneficiary(result.instrument_ID)">  <!-- <<==== Beneficiary Button  -->
                                <i class="bi bi-person-plus-fill"></i>                            
                            </div>
                            <small>Beneficiary</small>                                                              
                        </div>
                        
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-3)" @click="estate(result.instrument_ID)">  <!-- <<==== Estate Button  -->
                                <i class="bi bi-archive-fill"></i>                           
                            </div>
                            <small>Estate</small>                                                              
                        </div>
                        
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-4)" @click="guardian(result.instrument_ID)">  <!-- <<==== Guardian Button  -->
                                <i class="bi bi-shield-fill-plus"></i>                         
                            </div>
                            <small>Guardian</small>                                                              
                        </div>
                        
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-5)" @click="declaration(result.instrument_ID)">  <!-- <<==== Declaration & Trust Button  -->
                                <i class="bi bi-journal-bookmark-fill"></i>                          
                            </div>
                            <small>Declaration & Trust</small>                                                              
                        </div>
                        <!-- ========== Change to generate draft ================ -->  
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-6)" @click="generate_draft(result.instrument_ID)">  <!-- <<==== Edit Draft Button  -->
                                <i class="bi bi-pencil-square"></i>                          
                            </div>
                            <small>Generate Draft</small>                                                              
                        </div>
                        <!-- ========== Change to edit and print draft ================ -->  
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-7)" @click="edit_draft(result.instrument_ID)">  <!-- <<==== Print Draft Button  -->
                                <i class="bi bi-printer-fill"></i>                         
                            </div>
                            <small>Edit & Print Draft</small>                                                              
                        </div>
                        <!-- ========== Change to edit and print draft ================ -->  
                        <div class="col-4 col-sm-3 col-lg-4 w-100 h-100 text-center">
                            <div class="search-testator-button d-flex justify-content-center align-items-center mx-auto mb-1" style="background-color: var(--button-9)" @click="instrument_information(result.instrument_ID)">  <!-- <<==== Print Draft Button  -->
                                <i class="bi bi-card-list"></i>                      
                            </div>
                            <small>Show Infomation</small>                                                              
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- ========== Search Result END ================ -->  
      </div>   
    </div>
    <!-- ^^^ content container END ^^^ -->    
  </div>
<!-- 
    beneficiary
    declaration & trust
    estate
    executor
    guardian
    edit draft
    print draft
     -->
</template>

<script>
import axios from 'axios';
import store from "../../store";
export default {
    name: "SearchTestator",
    components: {

    },
    data() {

        return {
            testator_name: "",
            results:[],
            testators:[],
            feedback: "",
          
        };
    },
    methods: {
        executor(id){
            store.commit("InstrumentID",id);
            this.$router.push("/executors");
        },
       
        beneficiary(id){
            store.commit("InstrumentID",id);
            this.$router.push("/beneficiaries");
        },
        update_testator(id){
            store.commit("InstrumentID",id);
            this.$router.push("/update-testator");
        },
        estate(id){
            store.commit("InstrumentID",id);
            this.$router.push("/estates");
        },
        guardian(id){
            store.commit("InstrumentID",id);
            this.$router.push("/guardians");
        },
        declaration(id){
            store.commit("InstrumentID",id);
            this.$router.push("/declarations-trusts");
        },
        generate_draft(id){
            store.commit("InstrumentID",id);
            this.$router.push("/instrument-editor");
            store.commit("DraftName","First Draft");
            store.commit("DraftID","");
            console.log(id)
        },
        edit_draft(id){
            store.commit("InstrumentID",id);
            this.$router.push("/instrument-drafts");
            console.log(id)
        },
        instrument_information(id){
            store.commit("InstrumentID",id);
            this.$router.push("/instrument-information");
        },
        search_testator() {
            this.url = this.HostUrl + this.ScriptUrl + "testator.php"

            store.commit("SearchTestator", this.testator);
            if(this.testator != ""){
                axios.post(this.url, 
                    {
                        request: 5,
                        testator: this.testator
                    }
                    )
                    .then(response => {
                        console.log(response.data)
                        if(response.data != 'empty') { 
                            this.results = response.data;
                        }
                    })
                    
                    .catch(function (error) {
                        console.log(JSON.stringify(error));
                        console.log("error response :" + error.response)
                        console.log("error status :" + error.status)
                    });
            }
        },
        show_all_testator() {
            this.url = this.HostUrl + this.ScriptUrl + "testator.php"
           
            axios.post(this.url, 
                {
                    request: 6,
                }
                )
                .then(response => {
                    this.testators = response.data;
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        }
        
        
    },
    created() {
 
        this.url = this.HostUrl + this.ScriptUrl + "testator.php"
        this.testator = this.SearchTestator
        this.search_testator()
        this.show_all_testator()

       
    },

    computed: {
         Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        SearchTestator() {
            return this.$store.state.search_testator;
        }

       
    }

};
</script>

<style>
@import '../../assets/css/body.css';
@import '../../assets/css/instrument-generator.css';
select {
    display: grid;
}



</style>
