<template>
<div class="col-12 p-0">
    <div class="login-bg">
        <div class="container w-100 position-relative h-100">
            <div class="login z-depth-3 rounded-2" id="login-card">
                <div class="row">
                    <div class="col-12 text-center login-logo my-4">
                        <img src="../assets/images/logo/logo-main.png" draggable="false" alt="logo">
                    </div>
                    <div class="col-12 my-4">
                        <div class="w-75 mx-auto">
                            <label class="w-100 ms-2">Username</label>
                            <input class="w-100 px-2 login-input rounded-1" type="text" id="username" v-model="username" placeholder="Username">
                        </div>       
                        <div class="mt-4 w-75 mx-auto">
                            <label class="w-100 ms-2" style="font-weight:bold" for="password">Password:</label>            
                            <input class="w-100 px-2 login-input rounded-1" type="password" id="password" v-model="password" v-on:keyup.enter="login(username,password)" placeholder="Password">
                        </div>   
                        <div class="mt-4 w-75 mx-auto">
                            <v-checkbox class="" label="Remember me" v-model="remember"></v-checkbox>   
                        </div>                               
                    </div>
                    <div class="col-12 mb-4">
                        <div class="field center-align">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p>
  
                            <button @click="login(username,password)" class="btn1 btn-large btn-extended grey lighten-4 black-text my-1 rounded-2" type="submit">
                                <span style="font-size:smaller;">Sign In </span>         
                            </button>

                            <button @click="forget" class="btn1 btn-large btn-extended grey lighten-4 black-text my-1 rounded-2" style="margin-left:20px">
                                <span style="font-size:smaller;"> Forget Password </span>          
                            </button>
                        </div>
                    </div>
                   
                </div>           
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="text-center text-light">
                        <small id="login-copyright">Lincoln Cybernetics Sdn Bhd (Company No.: 1000909-D)</small>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</div>    
</template>

<script>
import store from "../store";
import axios from 'axios';

export default {
    components: {
       
    },
    data() {
        return {
            username: "",
            password: "",
            feedback: "",
            result: '',
            remember: false,
            url: ''
        };
    },
    methods: {

        login: function (username, password) {
            this.show = true;
            
            this.feedback = ''
            axios.post(this.url, 
                {
                    username: username,
                    password: password
                }
                )
                .then(response => {
                    this.result = response.data;
                    if(this.result[0].login_status == "success") {
                        store.commit("IsLogin", true);
                        store.commit("Username", username);
                        store.commit("UserID", this.result[0].ID);
                        if(this.remember == true) {
                            store.commit("RememberUsername", username)
                            store.commit("RememberPassword", password)
                            store.commit("RememberBoolean", true)
                        }
                        else {
                            store.commit("RememberUsername", '')
                            store.commit("RememberPassword", '')
                            store.commit("RememberBoolean", false)
                        }
                        this.$router.push("/");
                    }
                    else {
                        this.feedback = this.result
                    }
                    this.show = false
           
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
           

        },
        forget() {
            this.$router.push("/forget-password");
        }

    },
    created() {
        this.url = this.HostUrl + this.ScriptUrl + "login.php"
        this.remember = this.RememberBoolean
        if(this.remember){
            this.username = this.RememberUsername
            this.password = this.RememberPassword
        }
       

    },

    computed: {
       
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        RememberBoolean(){
            return this.$store.state.remember_boolean;
        },
        RememberUsername(){
            return this.$store.state.remember_username;
        },
        RememberPassword(){
            return this.$store.state.remember_password;
        }
    }
};
</script>
<style>
label {
    font-weight: bold;
    color: black;
}

.btn1 {
    font-weight: bold;
    margin-bottom: 20%;
    margin-top: 10%;
}

.field {
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
}
@import '../assets/css/login.css';
</style>
