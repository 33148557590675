const mainjs = {
    getTarget(target) {
        return document.querySelector(target);
    },
    getChild(parent, childClass) {
        return parent.querySelector(childClass);
    },
    getAllTarget(target){
        return document.querySelectorAll(target);
    },
    getChildren(parent,childrenClass){
        return parent.querySelectorAll(childrenClass);
    },
    addClass(target,targetClass) {
        if (!target.classList.contains(targetClass)) {
            target.classList.add(targetClass);
        }
    },
    removeClass(target,targetClass) {
        target.classList.remove(targetClass);
    },
    getYaxis(){
        return window.pageYOffset;
    },
    getXaxis(){
        return window.pageXOffset;
    },
    returnTo(x,y){
        window.scrollTo(x,y);
    }
}

const instrumentForm = { 
    Xaxis:'',
    Yaxis:'',
    // async checkY() {
    //     console.log(mainjs.getYaxis());
    // },
    async adjustBody(){
        const body = await mainjs.getTarget('body');
        const html = await mainjs.getTarget('html');      
        this.Yaxis=mainjs.getYaxis();  
        if (!mainjs.getYaxis()<=0){
            this.Yaxis=mainjs.getYaxis();
        }
        if (!mainjs.getXaxis()<=0){
            this.Xaxis=mainjs.getXaxis();
        }
        body.setAttribute('style',`top:-${this.Yaxis}px`);
        html.setAttribute('style','scroll-behavior: initial');
    },
    async readjustBody(){
        const body = await mainjs.getTarget('body');
        const html = await mainjs.getTarget('html');
        await body.removeAttribute('style');        
        await mainjs.returnTo(this.Xaxis,this.Yaxis);
        this.Xaxis='';
        this.Yaxis='';
        await html.removeAttribute('style');
    },
    async closeForm(formId){
        const form = await mainjs.getTarget(formId);   
        const formOverlay = await mainjs.getChild(form,'.instrument-form-overlay');
        const formModal = await mainjs.getChild(form,'.instrument-form-modal');
        const body = await mainjs.getTarget('body');
                
        mainjs.removeClass(body, 'fix-body');
        this.readjustBody();
        mainjs.addClass(formOverlay, 'instrument-form-overlay-deactive');
        mainjs.addClass(formModal,'instrument-form-modal-deactive');
    },
    async openForm(formId){
        const body = await mainjs.getTarget('body');
        const form = await mainjs.getTarget(formId);   
        const overlay = await mainjs.getChild(form,'.instrument-form-overlay');
        const modal = await mainjs.getChild(form,'.instrument-form-modal');

        this.adjustBody();             
        setTimeout(() => {
            mainjs.removeClass(overlay,'instrument-form-overlay-deactive');
            mainjs.removeClass(modal, 'instrument-form-modal-deactive');
            mainjs.addClass(body,'fix-body'); 
        }, 1);  
    },    
    async setupOpenForm(addBtnId) {
        const addBtn = await mainjs.getTarget(addBtnId);
        await addBtn.addEventListener('click',()=>{
            this.openForm('#instrument-form');
        });
    },
    async setupEditBtn() {
        const editBtns = await mainjs.getAllTarget('.instrument-edit-btn');
        for (const editBtn of editBtns) {
            editBtn.addEventListener('click',()=>{
                this.openForm('#instrument-edit-form')
            });
        }
    },
    async setupCloseForm(formId) {
        const form = await mainjs.getTarget(formId);        
        await form.addEventListener('click',(e)=>{
            // const modal = mainjs.getChild(form,'.instrument-form-modal');
            const closeBtn = mainjs.getChild(form,'.instrument-close-btn');
            if(closeBtn.contains(e.target)){
                this.closeForm(formId);
            }
        });
    },
    
    async initAddForm(){
        await this.setupOpenForm('#instrument-add-btn');
        await this.setupCloseForm('#instrument-form');
    },

    async setupDeleteBtn(formId="#insttrument-form"){
        const form = await mainjs.getTarget(formId);
        const lists = await mainjs.getChildren(form,'.estate-beneficiary-list');        
        if (lists) {
            for (const list of lists) {
                const section = await mainjs.getChild(list,'.estate-beneficiary-name');
                const select = await mainjs.getChild(section,'select');
                const deleteBtn = await mainjs.getChild(list,'.estate-beneficiary-delete');
                if (select.value==false){
                    mainjs.removeClass(deleteBtn,'estate-beneficiary-delete-hide');
                }else {
                    mainjs.addClass(deleteBtn,'estate-beneficiary-delete-hide');
                }
                deleteBtn.addEventListener('click', ()=>{
                    mainjs.addClass(list,'estate-beneficiary-list-deactive');
                    setTimeout(() => {
                        list.remove();
                    }, 400);                    
                });
            }
        }
    }, 
    async setCheckbox(checkboxClass,func){
        const checkboxes = await mainjs.getAllTarget(checkboxClass);
        for (const checkbox of checkboxes) {
            checkbox.addEventListener('click',()=>{
                func
            })
        }
        
    },
    async scanCheckbox(targetId,addedClass){
        const container = await mainjs.getTarget(targetId);
        const checkbox = await mainjs.getChild(container,'.dnt-check');
        const input = await mainjs.getChild(checkbox, 'input');
        const box = await mainjs.getChild(container,'.dnt-box');
        if (input.checked) {
            box.classList.remove(addedClass);
        } else {
            box.classList.add(addedClass);
        }
    },
    async setupCheckbox(targetId,addedClass){
        const container = await mainjs.getTarget(targetId);
        const checkbox = await mainjs.getChild(container,'.dnt-check');
        await checkbox.addEventListener('click',()=>{
            this.scanCheckbox(targetId,addedClass);
        });
    },

    async stripedBeneficiary(){
        const lists = await mainjs.getAllTarget('.estate-beneficiary-list');
        for(let i = 0; i<lists.length;i++){
            const count = i%2;
            if ( count == 0 ) {
                lists[i].setAttribute('style','background: #ededed');
            } else {
                lists[i].removeAttribute('style');
            }
        }
    },
    async setupSubstitute(){
        const lists = await mainjs.getAllTarget('.estate-beneficiary-list');
        for (const list of lists) {
            const checkbox = await mainjs.getChild(list,'.estate-beneficiary-checkbox');
            if (checkbox) {
                const input = await mainjs.getChild(checkbox,'input');
                if (input.checked) {
                    const remark = await mainjs.getChild(list,'.substitute-remark');
                    mainjs.removeClass(remark,'d-none');
                    setTimeout(() => {
                        mainjs.removeClass(remark,'substitute-remark-hide') 
                    }, 100);
                } else {
                    const remark = await mainjs.getChild(list,'.substitute-remark');
                    mainjs.addClass(remark,'substitute-remark-hide');
                    setTimeout(() => {
                        mainjs.addClass(remark,'d-none') ;
                    }, 500);
                }
            }
        }
    },    
    async showRemark(){
        const remarks = await mainjs.getAllTarget('.remark-textbox');
        for (const remark of remarks) {
            const text = await mainjs.getChild(remark,'span');
            const content = text.textContent;
            if (content) {
                mainjs.removeClass(remark,'d-none');
            } else {
                mainjs.addClass(remark,'d-none');
            }
        }
    },
    async setupEstateBeneficiary(){
        this.stripedBeneficiary();
        // this.setCheckbox('.checkbox',this.setupSubstitute());        
        this.showRemark();  
    },    
    async checkResiduary(){
        const residuaryDiv = await mainjs.getTarget('#residuary-estate');
        const checkbox = await mainjs.getChild(residuaryDiv,'.residuary-checkbox');
        const input = await mainjs.getChild(checkbox,'input');
        const body = await mainjs.getChild(residuaryDiv,'.estate-beneficiary-list');
        if(input.checked){
            mainjs.removeClass(body,'residuary-disabled');
        }else{
            mainjs.addClass(body,'residuary-disabled');
        }
    },
    async setupResiduary(){
        const residuaryDiv = await mainjs.getTarget('#residuary-estate');
        const checkbox = await mainjs.getChild(residuaryDiv,'.residuary-checkbox');
        checkbox.addEventListener('click',this.checkResiduary);   
    },
    async adjustEditor() {
        const body = await document.querySelector("#editor > div.w-e-text-container");
        body.setAttribute('style','border:1px solid #c9d8db; border-top:none; max-height:900px; z-index:500;overflow:auto;');
    },
}

export {instrumentForm};