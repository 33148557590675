<template>      
<div class="container">
    <div class="row body-container">
        <div class="col-12">
            <div class="body-card shadow rounded-2">
                <!-- ========== code start here  ========== -->

                <!-- ========== Title  ========== -->
                <div style="text-align:center" class="mb-4">
                    <h2>Testator Information</h2>
                </div>
                <!-- ========== User Profile  ========== -->              
                <div class="row mt-4" v-for="(data, index) in results" :key="index">    
                    <!-- ========== Personal Info  ========== -->    
                    <div class="col-lg-4">
                        <div class="row g-2">
                            <div class="col-12">
                                <label for="name">Name:</label>
                                <input type="text" v-model="data.name">
                            </div>                                                                                
                            <div class="col-12">
                                <label for="name">Phone:</label>
                                <input type="text" v-model="data.phone">
                            </div>
                            <div class="col-12">
                                <label for="name">IC:</label>
                                <input type="text" v-model="data.ic">
                            </div>
                        </div>                    
                    </div>
                    <!-- ========== Address  ========== -->
                    <div class="col-lg-8">
                        <div class="row g-2"> 
                            <div class="col-lg-2">                                        
                                <label for="unit">Unit No.:</label>
                                <input type="text" v-model="data.unit_no">                                        
                            </div>                                    
                            <div class="col-lg-10">                                        
                                <label for="address">Address:</label>
                                <input type="text" v-model="data.address">                                        
                            </div>    
                            <div class="col-lg-6">                                        
                                <label for="postcode">Postcode:</label>
                                <input type="text" v-model="data.postcode">                                        
                            </div>           
                             <div class="col-lg-6">                                        
                                <label for="address">City:</label>
                                <input type="text" v-model="data.city">                                        
                            </div>   
                             <div class="col-lg-6">                                        
                                <label for="state">State:</label>
                                <input type="text" v-model="data.states">                                        
                            </div>                         
                            <div class="col-lg-6">                                        
                                <label for="country">Country:</label>
                                <input type="text" v-model="data.country">                                        
                            </div>      
                        </div>
                    </div>
                     <!-- ========== Remark  ========== -->
                    <div>
                         <label for="remark">Remark:</label>
                        <input type="text" v-model="data.remark">      
                    </div>
                    <!-- ========== Button and error  ========== -->
                    <div class="col-12">
                        <div class="center-align col-12">
                            <p v-if="feedback" class="red-text">{{ feedback }}</p> 
                            <router-link :to="{ name: 'SearchTestator' }" style="text-decoration: none;">
                                <v-btn color="green" dark> 
                                    <i class="bi bi-arrow-left-circle pe-1"></i><span>Back</span>
                                </v-btn>
                            </router-link>                                   
                            <v-btn color="blue" dark @click="update_testator(data.ID,index)" class="ms-2">
                                <span>Update</span><i class="bi bi-check2-circle ps-1"></i>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <!-- ========== code end here  ========== -->
            </div>            
        </div>          
    </div>
</div>     
</template>

<script>
import axios from 'axios';

export default {
    name: "CreateTestator",
    components: {

    },
    data() {

        return {
            results:[],
            feedback: null,
      
        };
    },
    methods: {

        update_testator(id,index) {
           var name = this.results[index].name
           var phone = this.results[index].phone
           var address = this.results[index].address
           var ic = this.results[index].ic
           var unit_no = this.results[index].unit_no
           var postcode = this.results[index].postcode
           var city = this.results[index].city
           var states = this.results[index].states
           var country = this.results[index].country
           var remark = this.results[index].remark
           if(name == "" ) {
                alert("Please input a name for testator !")
                return    
            }
            if(ic == "" ) {
                alert("Please input an Identity Card Number for testator !")
                return    
            }
            if(phone == "" ) {
                alert("Please input a phone number for testator !")
                return    
            }
            axios.post(this.url, 
                {
                    request: 3,
                    name: name,
                    phone: phone,
                    address: address,
                    ic: ic,
                    unit_no: unit_no,
                    postcode: postcode,
                    city: city,
                    states: states,
                    country: country,
                    user_id: this.UserID,
                    remark: remark,
                    id: id
                })
                .then(response => {
                    console.log(response.data)
                    alert(response.data)
                    //this.$router.push("/executors");
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        show_testator(){
            console.log(this.url)
            console.log(this.InstrumentID)
            axios.post(this.url, 
                {
                    request: 1,
                    instrument_id: this.InstrumentID,
                })
                .then(response => {
                    console.log(response.data)
                    this.results = response.data
                    
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },

        
        
    },
    created() {
 
        this.url = this.HostUrl + this.ScriptUrl + "testator.php"
        this.show_testator()
    },

    computed: {
        Username() {
            return this.$store.state.username;
        },
        UserID() {
            return this.$store.state.user_id;
        },
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        InstrumentID() {
            return this.$store.state.instrument_id;
        },
        InstrumentReference() {
            return this.$store.state.instrument_reference;
        }

    }

};
</script>

<style>
@import '../../assets/css/body.css';
@import '../../assets/css/profile.css';
select {
    display: grid;
}



</style>
